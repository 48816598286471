import { Box } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useFetchHostedPaymentsPageMutation } from "../../../api/worldpayService";
import { toastOptionsAtom } from "../../../atoms";
import { InvoiceOverviewInfo } from "../../../types";
import Loading from "../../common/loading/Loading";

type Props = {
  invoiceOverview: InvoiceOverviewInfo | undefined;
  orgId: number;
};

export const HostedPaymentsPage = ({ invoiceOverview, orgId }: Props) => {
  const [hostedPaymentsPageUrl, setHostedPaymentsPageUrl] = useState<string>();
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const fetchHostedPaymentsPageMutation = useFetchHostedPaymentsPageMutation();
  useEffect(() => {
    if (invoiceOverview) {
      fetchHostedPaymentsPageMutation.mutate({
        orgId: orgId,
        accountId: invoiceOverview?.accountId,
        invoiceNumber: invoiceOverview?.invoiceNumber,
        amount: invoiceOverview?.invoiceAmount,
        addressLine1: invoiceOverview?.address.addressLine1,
        addressLine2: invoiceOverview?.address.addressLine2 ?? "",
        city: invoiceOverview?.address.city,
        state: invoiceOverview?.address.state,
        zip: invoiceOverview?.address.zip,
      });
    }
  }, []);

  useEffect(() => {
    if (fetchHostedPaymentsPageMutation.isSuccess) {
      const hostedPaymentsPageUrl = fetchHostedPaymentsPageMutation.data;
      setHostedPaymentsPageUrl(hostedPaymentsPageUrl);
    }

    if (fetchHostedPaymentsPageMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    fetchHostedPaymentsPageMutation.isSuccess,
    fetchHostedPaymentsPageMutation.isError,
    fetchHostedPaymentsPageMutation.isLoading,
    fetchHostedPaymentsPageMutation.data,
    fetchHostedPaymentsPageMutation.error,
  ]);

  return (
    <Box
      sx={{
        //   boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        minHeight: "630px",
        py: "auto",
      }}
    >
      <iframe
        style={{
          height: "630px",
          width: "100%",
          border: "none",
        }}
        src={hostedPaymentsPageUrl}
      ></iframe>
      {fetchHostedPaymentsPageMutation.isLoading && (
        <Loading
          message=" "
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        />
      )}
    </Box>
  );
};
