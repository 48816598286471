import { useMutation, useQuery } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import { ApiError, QueryOptions, WashType } from "../types";
import { DEFAULT_QUERY_OPTIONS } from "../constants";
import { AxiosError, AxiosResponse } from "axios";

export const useFetchWashTypesQuery = (
  orgId: number,
  fetchOnMount: boolean = true
) => {
  const fetchWashTypes = async (): Promise<WashType[]> => {
    const path = config.api.washTypeService.getAllWashTypes.replace(
      "{orgId}",
      orgId.toString()
    );
    const response = await client.get<any>(path);
    return response.data.Washtypes;
  };

  return useQuery(["fetchWashTypes", orgId], fetchWashTypes, {
    retry: false,
    enabled: fetchOnMount,
  });
};

export const useFetchMiscWashTypesQuery = (
  orgId: number,
  queryOptions?: QueryOptions<WashType[]>
) => {
  const fetchMiscWashTypes = async (): Promise<WashType[]> => {
    const path =
      config.api.washTypeService.getAllMiscellaneousWashTypes.replace(
        "{orgId}",
        orgId.toString()
      );

    const response = await client.get<{
      Washtypes: WashType[];
    }>(path);

    return response.data.Washtypes;
  };

  return useQuery(["fetchMiscWashTypes", orgId], fetchMiscWashTypes, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useCreateWashTypeMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    Omit<WashType, "id">,
    unknown
  >(async (data: Omit<WashType, "id">) => {
    const path = config.api.washTypeService.createWashType.replace(
      "{orgId}",
      data.orgId.toString()
    );

    const response = await client.post(path, data);
    return response;
  });

  return mutation;
};

export const useUpdateWashTypeMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    WashType,
    unknown
  >(async (data: WashType) => {
    const path = config.api.washTypeService.updateWashType
      .replace("{orgId}", data.orgId.toString())
      .replace("{id}", data.id.toString());

    const response = await client.patch(path, data);
    return response;
  });

  return mutation;
};

export const useDeleteWashTypeMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    Pick<WashType, "id" | "orgId">,
    unknown
  >(async (data: Pick<WashType, "id" | "orgId">) => {
    const path = config.api.washTypeService.deleteWashType
      .replace("{orgId}", data.orgId.toString())
      .replace("{id}", data.id.toString());

    const response = await client.delete(path);
    return response;
  });

  return mutation;
};

export const useDeleteWashTypesMutation = () => {
  const mutation = useMutation(
    async (data: { orgId: number; ids: number[] }) => {
      const { orgId, ...ids } = data;
      const path = config.api.washTypeService.deleteWashTypes.replace(
        "{orgId}",
        orgId.toString()
      );
      const response = await client.delete(path, {
        data: ids,
      });
      return response;
    }
  );
  return mutation;
};
