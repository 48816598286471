import { useMutation, useQuery } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import {
  ApiBasePathParams,
  ApiError,
  InvoiceInfo,
  InvoiceNote,
  InvoiceOverviewInfo,
  InvoiceRequestParams,
  QueryOptions,
} from "../types";
import { DEFAULT_QUERY_OPTIONS } from "../constants";
import { AxiosError, AxiosResponse } from "axios";
import { InvoiceStatus } from "../enums";

const buildInvoicesUrl = (
  pathTemplate: string,
  invoiceRequestParams: InvoiceRequestParams,
  excludePagination: boolean = false
): string => {
  const { orgId, accountId, filters } = invoiceRequestParams;

  let path = pathTemplate
    .replace("{orgId}", orgId.toString())
    .replace("{accountId}", accountId.toString());

  const queryParams = new URLSearchParams(
    Object.entries(filters).reduce((params, [key, value]) => {
      if (value !== undefined && value !== null) {
        if (excludePagination && (key === "page" || key === "pageSize"))
          return params;
        params[key] = value.toString();
      }
      return params;
    }, {} as Record<string, string>)
  );

  if (queryParams.toString()) {
    path += `?${queryParams.toString()}`;
  }

  return path;
};

export const useFetchInvoicesQuery = (
  invoiceRequestParams: InvoiceRequestParams,
  fetchOnMount: boolean = true
) => {
  const fetchPaginatedInvoices = async (): Promise<InvoiceInfo> => {
    const path = buildInvoicesUrl(
      config.api.invoiceService.getPaginatedInvoices,
      invoiceRequestParams,
      false
    );

    const response = await client.get<any>(path);
    return response.data;
  };

  return useQuery(
    ["fetchPaginatedInvoices", invoiceRequestParams],
    fetchPaginatedInvoices,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useFetchPreviewInvoiceQuery = (
  orgId: number,
  accountId: number,
  invoiceNumber: string,
  fetchOnMount: boolean = true
) => {
  const fetchPreviewInvoice = async (): Promise<ArrayBuffer> => {
    const path = config.api.invoiceService.previewInvoice
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{invoiceNumber}", invoiceNumber.toString());
    const response = await client.get<any>(path, {
      headers: {
        "Content-Type": "application/pdf",
        Accept: "application/pdf",
      },
      responseType: "arraybuffer",
      responseEncoding: "binary",
    });
    return response.data;
  };

  return useQuery(
    ["fetchPreviewInvoice", orgId, accountId, invoiceNumber],
    fetchPreviewInvoice,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useFetchDownloadInvoicesCsvQuery = (
  invoiceRequestParams: InvoiceRequestParams,
  queryOptions?: QueryOptions<string>
) => {
  const downloadInvoices = async (): Promise<string> => {
    const path = buildInvoicesUrl(
      config.api.invoiceService.downloadInvoicesCsv,
      invoiceRequestParams,
      true
    );
    const response = await client.get<any>(path, {
      headers: {
        "Content-Type": "text/csv",
        Accept: "text/csv",
      },
    });

    return response.data;
  };
  return useQuery(["downloadInvoices"], downloadInvoices, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useFetchInvoiceOverviewQuery = (
  orgId: number,
  accountId: number,
  invoiceNumber: string,
  queryOptions?: QueryOptions<string>
) => {
  const fetchInvoiceOverview = async (): Promise<InvoiceOverviewInfo> => {
    const path = config.api.invoiceService.getInvoiceOverview
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{invoiceNumber}", invoiceNumber.toString());

    const response = await client.get<{
      data: InvoiceOverviewInfo;
    }>(path);

    return response.data.data;
  };

  return useQuery(
    ["fetchInvoiceOverview", orgId, accountId, invoiceNumber],
    fetchInvoiceOverview,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

export const useFetchInvoiceNotesQuery = (
  orgId: number,
  accountId: number,
  invoiceNumber: string,
  queryOptions?: QueryOptions<string>
) => {
  const fetchInvoiceNotes = async (): Promise<InvoiceNote[]> => {
    const path = config.api.invoiceService.getInvoiceNotes
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{invoiceNumber}", invoiceNumber.toString());

    const response = await client.get<{
      invoiceNotes: InvoiceNote[];
    }>(path);

    return response.data.invoiceNotes;
  };

  return useQuery(
    ["fetchInvoiceNotes", orgId, accountId, invoiceNumber],
    fetchInvoiceNotes,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

export const useCreateInvoiceNoteMutation = () => {
  const mutation = useMutation<
    unknown,
    AxiosError<ApiError>,
    {
      orgId: number;
      accountId: number;
      invoiceNumber: string;
      message: string;
    },
    unknown
  >(
    async (data: {
      orgId: number;
      accountId: number;
      invoiceNumber: string;
      message: string;
    }) => {
      const { orgId, accountId, invoiceNumber, message } = data;
      const path = config.api.invoiceService.createInvoiceNote
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post<{ orgId: number }>(path, {
        message,
      });

      return response.data;
    }
  );

  return mutation;
};

export const useBulkUpdateInvoiceStatusMutation = () => {
  const mutation = useMutation<
    unknown,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      invoiceNumbers: string[];
      invoiceStatus: InvoiceStatus;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        invoiceNumbers: string[];
        invoiceStatus: InvoiceStatus;
      }
    ) => {
      const { orgId, accountId, invoiceNumbers, invoiceStatus } = data;
      const path = config.api.invoiceService.bulkUpdateInvoiceStatus
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString());

      const response = await client.post(path, {
        invoiceNumbers,
        invoiceStatus,
      });

      return response;
    }
  );

  return mutation;
};

export const useCreateOnDemandInvoiceMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    {
      orgId: number;
      accountId: number;
      washInventoryIds: number[];
      fromDate: string;
      toDate: string;
    }
  >(
    async (data: {
      orgId: number;
      accountId: number;
      washInventoryIds: number[];
      fromDate: string;
      toDate: string;
    }) => {
      const { orgId, accountId, washInventoryIds, fromDate, toDate } = data;
      const path = config.api.invoiceService.createOnDemandInvoice
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString());

      const response = await client.post(path, {
        transactionsFrom: fromDate,
        transactionsTo: toDate,
        washInventoryIds: washInventoryIds,
      });

      return response;
    }
  );

  return mutation;
};
