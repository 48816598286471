import {
  CircularProgress,
  Divider,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { Tabs } from "../common";
import { InvoiceOverviewInfo, TabItem } from "../../types";
import { useEffect, useState } from "react";
import { InvoiceActions, InvoiceOverview } from "./fragments";
import { InvoiceHistory } from "./fragments/InvoiceHistory";
import { InvoiceNotes } from "./fragments/InvoiceNotes";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFetchInvoiceOverviewQuery } from "../../api/invoiceService";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { userAtom } from "../../atoms";
import { useAtom } from "jotai";
import { Role } from "../../enums";
import {
  INVOICE_STATUS_TARGET_ACTIONS_ADMIN,
  INVOICE_STATUS_TARGET_ACTIONS_CUSTOMER,
} from "../../constants";

export const InvoiceDetails = () => {
  const [user] = useAtom(userAtom);
  const params = useParams<{ invoiceNumber: "string" }>();
  const [queryParams] = useSearchParams();
  const orgId = Number(queryParams.get("orgId")) || 0;
  const accountId = Number(queryParams.get("accountId")) || 0;
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [invoiceOverview, setInvoiceOverview] = useState<InvoiceOverviewInfo>();
  const navigate = useNavigate();
  const tabItems: TabItem[] = [
    {
      key: "Overview",
      value: 1,
    },
    {
      key: "History",
      value: 2,
    },
    {
      key: "Notes",
      value: 3,
    },
  ];

  const fetchInvoiceOverviewQuery = useFetchInvoiceOverviewQuery(
    orgId,
    accountId,
    params.invoiceNumber || ""
  );

  useEffect(() => {
    if (fetchInvoiceOverviewQuery.isSuccess) {
      setInvoiceOverview(fetchInvoiceOverviewQuery.data);
    }
  }, [
    fetchInvoiceOverviewQuery.isSuccess,
    fetchInvoiceOverviewQuery.isError,
    fetchInvoiceOverviewQuery.data,
    fetchInvoiceOverviewQuery.error,
    fetchInvoiceOverviewQuery.isLoading,
  ]);

  const handleReturn = () => {
    navigate(-1);
  };

  const handleRefresh = () => {
    fetchInvoiceOverviewQuery.refetch();
  };

  return (
    <Grid
      container
      direction={"column"}
      spacing={3}
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "70vh",
        direction: "row",
        borderRadius: "10px",
        backgroundColor: "white",
        boxShadow: "0px 0px 4px 0px #00000040",
        p: 3,
      }}
    >
      {fetchInvoiceOverviewQuery.isLoading ||
      fetchInvoiceOverviewQuery.isFetching ||
      fetchInvoiceOverviewQuery.isRefetching ? (
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container size={12} direction={"row"}>
            <Grid container size={{ sm: 6, xs: 12 }} direction={"row"}>
              <Grid
                size={12}
                gap={3}
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <KeyboardBackspaceIcon
                  sx={{ transform: "translateY(-3px)", cursor: "pointer" }}
                  onClick={handleReturn}
                />
                <Typography fontSize={"1.4rem"}>
                  Invoice #{params?.invoiceNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              gap={2}
              size={{ sm: 6, xs: 12 }}
              direction={"row"}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "fit-content",
              }}
            >
              <Grid>
                {invoiceOverview && params.invoiceNumber && (
                  <InvoiceActions
                    status={invoiceOverview?.invoiceStatus}
                    invoiceNumbers={[params.invoiceNumber]}
                    orgId={orgId}
                    accountId={accountId}
                    handleRefresh={handleRefresh}
                    invoiceStatusTargetActions={
                      user?.roleCode === Role.Customer
                        ? INVOICE_STATUS_TARGET_ACTIONS_CUSTOMER
                        : INVOICE_STATUS_TARGET_ACTIONS_ADMIN
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12} direction={"row"}>
            <Tabs
              defaultTab="No Location"
              tabItems={tabItems}
              onTabChange={setSelectedTab}
              value={selectedTab}
            />
            <Divider sx={{ width: "100%" }} />
          </Grid>
          {params.invoiceNumber &&
            invoiceOverview &&
            (selectedTab === 1 ? (
              <InvoiceOverview
                invoiceOverview={invoiceOverview}
                refetch={fetchInvoiceOverviewQuery.refetch}
              />
            ) : selectedTab === 2 ? (
              <InvoiceHistory invoiceNumber={params.invoiceNumber} />
            ) : selectedTab === 3 ? (
              <InvoiceNotes />
            ) : (
              <></>
            ))}
        </>
      )}
    </Grid>
  );
};
