export enum ActiveStatus {
  Active = "active",
  Inactive = "inactive",
  Suspended = "suspended",
}

export enum ChargeType {
  Miscellaneous = "misc",
  TripMinimum = "tripmin",
  Wash = "wash",
}

export enum Role {
  SuperAdmin = "SADM",
  Admin = "ADM",
  Lead = "LEAD",
  Customer = "CUST",
}

export enum ReportType {
  AccountActivity = "AccountActivity",
  WashDetails = "WashDetails",
  Lead = "Lead",
}

export enum StatusCodes {
  NotFound = 404,
  Forbidden = 403,
}

export enum FileUploadStatus {
  NotStarted = "not-started",
  InProgress = "in-progress",
  Completed = "completed",
  Failed = "failed",
}

export enum InvoiceStatus {
  All = "all",
  Pending = "pending",
  SendToCustomer = "send-to-customer",
  SendToQuickbooks = "send-to-quickbooks",
  Cancelled = "cancelled",
  Deleted = "deleted",
  OnHold = "on-hold",
  PaymentReceived = "payment-received",
  InProgress = "in-progress",
}

export enum UserStatus {
  Active = "active",
  Inactive = "inactive",
}

export enum Module {
  AccountList = "account-list",
  AccountBasic = "account-basic",
  AccountContacts = "account-contacts",
  AccountFinancial = "account-financial",
  AccountFleet = "account-fleet",
  AccountLocation = "account-location",
  AccountPricing = "account-pricing",
  AdminDashboard = "admin-dashboard",
  All = "all",
  CustomerDashboard = "customer-dashboard",
  InvoiceList = "invoice-list",
  Invoice = "invoice",
  InvoiceNotes = "invoice-notes",
  ModuleList = "module-list",
  Module = "module",
  OrganizationList = "organization-list",
  Organization = "organization",
  Payment = "payment",
  RoleList = "role-list",
  Role = "role",
  SuperAdminDashboard = "super-admin-dashboard",
  UserList = "user-list",
  User = "user",
  WashInventoryList = "wash-inventory-list",
  WashInventory = "wash-inventory",
  WashTypeList = "wash-type-list",
  WashType = "wash-type",
  WashDetailsReport = "wash-details=report",
  AccountActivityReport = "account-activity-report",
  Notification = "notification",
}

export enum Action {
  Read = "read",
  Write = "write",
  Delete = "delete",
  All = "all",
}

export enum InvoicePaymentStatus {
  Overdue = "overdue",
  Pending = "pending",
  Paid = "paid",
}

export enum PaymentMethod {
  Ach = "ach",
  Cash = "cash",
  Check = "check",
  Card = "card",
}

export enum InvoicePaymentCallbackMessage {
  Cancelled = "cancelled",
  PaymentSuccess = "payment-success",
  None = "none",
}
