import { Box, Grid2 } from "@mui/material";
import { ActionIconsV2, DataGrid, Modal, Tabs } from "../common";
import { searchObjects, theme } from "../../utils";
import { useAtom } from "jotai";
import {
  selectedOrgIdAtom,
  toastOptionsAtom,
  userPermissionAtom,
  washTypeDataGridPaginationAtom,
} from "../../atoms";
import { GridColDef } from "@mui/x-data-grid";
import { ChangeEvent, useEffect, useState } from "react";
import {
  useHasPermission,
  UserPermission,
} from "@zdistancelab-packages/ui-permission-validator";
import { Action, Module, Role } from "../../enums";
import { WashTypeForm, WashTypeToolbar } from "./fragments";
import {
  useCreateWashTypeMutation,
  useDeleteWashTypeMutation,
  useDeleteWashTypesMutation,
  useFetchMiscWashTypesQuery,
  useFetchWashTypesQuery,
  useUpdateWashTypeMutation,
} from "../../api";
import { TabItem, WashType } from "../../types";
import { WashTypeModel } from "../../models";

export const WashTypes = () => {
  const [washTypes, setWashTypes] = useState<WashType[]>([]);
  const [filteredWashTypes, setFilteredWashTypes] = useState<WashType[]>([]);
  const [miscWashTypes, setMiscWashTypes] = useState<WashType[]>([]);
  const [filteredMiscWashTypes, setFilteredMiscWashTypes] = useState<
    WashType[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isCreateWashTypeModalOpen, setIsCreateWashTypeModalOpen] =
    useState(false);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [userPermission] = useAtom<UserPermission>(userPermissionAtom);
  const [washTypePaginationModel, setWashTypePaginationModel] = useAtom(
    washTypeDataGridPaginationAtom
  );
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [selectedWashTypeIds, setSelectedWashTypeIds] = useState<number[]>([]);
  const hasWritePermission = useHasPermission(userPermission, [
    { module: Module.User, action: Action.Write },
  ]);
  const hasDeletePermission = useHasPermission(userPermission, [
    { module: Module.User, action: Action.Delete },
  ]);
  const fetchWashTypesQuery = useFetchWashTypesQuery(selectedOrgId);
  const fetchMiscWashTypesQuery = useFetchMiscWashTypesQuery(selectedOrgId);
  const createWashTypeMutation = useCreateWashTypeMutation();
  const updateWashTypeMutation = useUpdateWashTypeMutation();
  const deleteWashTypeMutation = useDeleteWashTypeMutation();
  const deleteWashTypesMutation = useDeleteWashTypesMutation();

  function handleOpenCreateWashTypeModal(): void {
    setIsCreateWashTypeModalOpen(true);
  }

  const handleCreateWashType = (
    data: Omit<WashType, "id" | "miscellaneous">
  ) => {
    createWashTypeMutation.mutate({
      name: data.name,
      description: data.description,
      unitOfMeasurement: data.unitOfMeasurement,
      code: data.code,
      orgId: data.orgId,
      rate: data.rate,
      miscellaneous: selectedTab === 0 ? false : true,
      quickbookReferenceId:
        data.quickbookReferenceId?.trim() === ""
          ? undefined
          : data.quickbookReferenceId,
    });
    setIsCreateWashTypeModalOpen(false);
  };

  const handleEditWashTypes = (data: Omit<WashType, "miscellaneous">) => {
    updateWashTypeMutation.mutate({
      id: data.id,
      name: data.name,
      description: data.description,
      unitOfMeasurement: data.unitOfMeasurement,
      code: data.code,
      orgId: data.orgId,
      rate: data.rate,
      miscellaneous: selectedTab === 0 ? false : true,
      quickbookReferenceId:
        data.quickbookReferenceId === ""
          ? undefined
          : data.quickbookReferenceId,
    });
  };

  const handleDeleteWashType = (data: Pick<WashType, "id" | "orgId">) => {
    deleteWashTypeMutation.mutate({
      id: data.id,
      orgId: data.orgId,
    });
  };

  const handleDeleteWashTypes = () => {
    deleteWashTypesMutation.mutate({
      orgId: selectedOrgId,
      ids: selectedWashTypeIds,
    });
  };

  const handleApplyFilters = () => {
    setFilteredWashTypes(getSearchedWashTypes(washTypes));
    setFilteredMiscWashTypes(getSearchedWashTypes(miscWashTypes));
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const handleCloseCreateWashTypeModal = () => {
    setIsCreateWashTypeModalOpen(false);
  };

  const handleRowSelectionChange = (rows: WashType[]) => {
    setSelectedWashTypeIds(rows.map((e) => e.id));
  };

  const getSearchedWashTypes = (washType: WashType[]) => {
    const searched = searchObjects(washType, [], searchTerm);
    return searched;
  };

  const tabItems: TabItem[] = [
    {
      key: "Wash Types",
      value: 0,
    },
    {
      key: "Misc Wash Types",
      value: 1,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "Code",
      width: 200,
      editable: false,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "unitOfMeasurement",
      headerName: "Unit of measurement",
      headerAlign: "center",
      align: "center",
      width: 250,
      editable: false,
    },
    {
      field: "rate",
      headerName: "Default Rate",
      headerAlign: "center",
      align: "center",
      width: 150,
      editable: false,
      valueGetter: (value) => {
        return `$ ${parseFloat(value).toFixed(2)}`;
      },
    },
    {
      field: "quickbookReferenceId",
      headerName: "Quickbooks Reference ID",
      width: 250,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
  ];

  if (hasWritePermission || hasDeletePermission) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: 150,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <ActionIconsV2
            onEdit={handleEditWashTypes}
            onDelete={handleDeleteWashType}
            rowData={params.row}
            formProps={{
              form: WashTypeForm,
              name: "editWashTypeForm",
            }}
            editProps={{
              title: "Edit Wash Type",
            }}
            deleteProps={{
              title: "Delete Wash Type",
            }}
          />
        );
      },
    });
  }

  useEffect(() => {
    setFilteredWashTypes(getSearchedWashTypes(washTypes));
    setFilteredMiscWashTypes(getSearchedWashTypes(miscWashTypes));
  }, [washTypes, miscWashTypes]);

  useEffect(() => {
    setFilteredWashTypes(getSearchedWashTypes(washTypes));
    setFilteredMiscWashTypes(getSearchedWashTypes(miscWashTypes));
  }, [searchTerm]);

  useEffect(() => {
    if (fetchWashTypesQuery.isSuccess) {
      const washTypes = WashTypeModel.buildWashType(
        fetchWashTypesQuery.data,
        selectedOrgId,
        false
      );
      setWashTypes(washTypes);
    }

    if (fetchWashTypesQuery.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
      setWashTypes([]);
    }
  }, [
    fetchWashTypesQuery.isSuccess,
    fetchWashTypesQuery.data,
    fetchWashTypesQuery.isError,
    fetchWashTypesQuery.isLoading,
    fetchWashTypesQuery.isFetching,
    fetchWashTypesQuery.isFetched,
    fetchWashTypesQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchMiscWashTypesQuery.isSuccess) {
      const miscWashTypes = WashTypeModel.buildWashType(
        fetchMiscWashTypesQuery.data,
        selectedOrgId,
        true
      );
      setMiscWashTypes(miscWashTypes);
    }

    if (fetchMiscWashTypesQuery.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
      setMiscWashTypes([]);
    }
  }, [
    fetchMiscWashTypesQuery.isSuccess,
    fetchMiscWashTypesQuery.data,
    fetchMiscWashTypesQuery.isError,
    fetchMiscWashTypesQuery.isLoading,
    fetchMiscWashTypesQuery.isFetching,
    fetchMiscWashTypesQuery.isFetched,
    fetchMiscWashTypesQuery.isRefetching,
  ]);

  useEffect(() => {
    if (createWashTypeMutation.isSuccess) {
      fetchWashTypesQuery.refetch();
      fetchMiscWashTypesQuery.refetch();
      setToastOptions({
        open: true,
        message: "Wash type created successfully",
        severity: "success",
      });
    }

    if (createWashTypeMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    createWashTypeMutation.isSuccess,
    createWashTypeMutation.data,
    createWashTypeMutation.isError,
    createWashTypeMutation.isLoading,
  ]);

  useEffect(() => {
    if (updateWashTypeMutation.isSuccess) {
      fetchWashTypesQuery.refetch();
      fetchMiscWashTypesQuery.refetch();
      setToastOptions({
        open: true,
        message: "Wash type updated successfully",
        severity: "success",
      });
    }

    if (updateWashTypeMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateWashTypeMutation.isSuccess,
    updateWashTypeMutation.data,
    updateWashTypeMutation.isError,
    updateWashTypeMutation.isLoading,
  ]);

  useEffect(() => {
    if (deleteWashTypeMutation.isSuccess) {
      fetchWashTypesQuery.refetch();
      fetchMiscWashTypesQuery.refetch();
      setToastOptions({
        open: true,
        message: "Wash type deleted successfully",
        severity: "success",
      });
    }

    if (deleteWashTypeMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteWashTypeMutation.isSuccess,
    deleteWashTypeMutation.data,
    deleteWashTypeMutation.isError,
    deleteWashTypeMutation.isLoading,
  ]);

  useEffect(() => {
    if (deleteWashTypesMutation.isSuccess) {
      fetchWashTypesQuery.refetch();
      fetchMiscWashTypesQuery.refetch();
      setToastOptions({
        open: true,
        message: "Wash types deleted successfully",
        severity: "success",
      });
    }

    if (deleteWashTypesMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteWashTypesMutation.isSuccess,
    deleteWashTypesMutation.data,
    deleteWashTypesMutation.isError,
    deleteWashTypesMutation.isLoading,
  ]);

  return (
    <>
      <Box
        sx={{
          marginTop: "50px",
          width: "100%",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
        }}
      >
        <Box>
          <Grid2
            container
            direction={"column"}
            spacing={2}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              padding: "20px",
              paddingTop: "0px",
              paddingBottom: "0px",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderBottom: "1px solid #E5E5E5",
            }}
          >
            <Tabs
              tabItems={tabItems}
              onTabChange={handleTabChange}
              value={selectedTab}
            />
          </Grid2>
          <Grid2
            container
            direction={"column"}
            sx={{
              marginTop: "0px",
              marginBottom: "8px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <WashTypeToolbar
              onApplyFilters={handleApplyFilters}
              handleSearch={handleSearch}
            />
          </Grid2>
          <Grid2 container direction={"column"} spacing={2}>
            <DataGrid
              columns={columns}
              rows={
                selectedTab === 0 ? filteredWashTypes : filteredMiscWashTypes
              }
              loading={
                fetchWashTypesQuery.isLoading ||
                fetchWashTypesQuery.isRefetching ||
                fetchMiscWashTypesQuery.isLoading ||
                fetchMiscWashTypesQuery.isRefetching ||
                createWashTypeMutation.isLoading ||
                updateWashTypeMutation.isLoading ||
                deleteWashTypeMutation.isLoading ||
                deleteWashTypesMutation.isLoading
              }
              paginationModel={washTypePaginationModel}
              setPaginationModel={setWashTypePaginationModel}
              onSelectedRowsChange={handleRowSelectionChange}
              sx={{
                border: "none",
                backgroundColor: theme.palette.secondary.light,
                padding: "20px",
                paddingTop: "0px",
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                height: "70vh",
              }}
              footerProps={{
                addBtnLabel: hasWritePermission ? "Add Wash Type" : "",
                hasAddIcon: true,
                hasRemoveIcon: true,
                removeBtnLabel: hasDeletePermission ? "Delete Selected" : "",
                onAddButtonClick: handleOpenCreateWashTypeModal,
                handleConfirmRemove: handleDeleteWashTypes,
                disableRemoveBtn: selectedWashTypeIds.length > 0 ? false : true,
                confirmationModalTitle: "Delete Wash Types",
                confirmationModalMessage:
                  selectedWashTypeIds.length == 1
                    ? "Are you sure you want to delete this wash type?"
                    : `Are you sure you want to delete these ${selectedWashTypeIds.length} wash types?`,
              }}
            />
          </Grid2>
        </Box>
      </Box>
      <Modal
        open={isCreateWashTypeModalOpen}
        onClose={handleCloseCreateWashTypeModal}
        title={"Add Wash Type"}
        content={
          <WashTypeForm
            id={"createWashTypeForm"}
            isCreateMode={true}
            handleEdit={handleCreateWashType}
          ></WashTypeForm>
        }
        form={"createWashTypeForm"}
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={handleCloseCreateWashTypeModal}
        sx={{
          modal: {
            width: { lg: "30%", xs: "80%", sm: "50%" },
          },
        }}
      />
    </>
  );
};
