import { useEffect, useState } from "react";
import {
  Box,
  Grid2 as Grid,
  styled,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
  Typography,
  Divider,
  InputAdornment,
} from "@mui/material";
import { TextField, SelectBox } from "../../common";
import { FaMoneyCheckAlt, FaCreditCard, FaMoneyBillWave } from "react-icons/fa";
import { RiBankFill } from "react-icons/ri";
import { PaymentMethod } from "../../../enums";
import { STATES } from "../../../constants";
import {
  DropdownItem,
  InvoiceOverviewInfo,
  PaymentDetails,
} from "../../../types";
import { IconType } from "react-icons";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";

const ToggleButton = styled(MuiToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#3e8bd2",
  },
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
});

export const paymentMethods: (DropdownItem & { icon: IconType })[] = [
  { key: "Card", value: PaymentMethod.Card, icon: FaCreditCard },
  { key: "Check", value: PaymentMethod.Check, icon: FaMoneyCheckAlt },
  { key: "ACH", value: PaymentMethod.Ach, icon: RiBankFill },
  { key: "Cash", value: PaymentMethod.Cash, icon: FaMoneyBillWave },
];

type Props = {
  invoice: InvoiceOverviewInfo | undefined;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  errors: FieldErrors<PaymentDetails>;
};

export const DetailsForm = ({ invoice, setValue, register, errors }: Props) => {
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.Card);

  useEffect(() => {
    setValue("paymentMethod", paymentMethod);
  }, [paymentMethod]);

  const handleChoosePaymentMethod = (
    event: React.MouseEvent<HTMLElement>,
    paymentMethod: PaymentMethod | null
  ) => {
    if (paymentMethod) setPaymentMethod(paymentMethod);
  };

  return (
    <Grid
      container
      size={12}
      spacing={{ xs: 0, sm: 4 }}
      direction={"row"}
      sx={{
        p: 4,
        boxShadow: "0px 0px 4px 0px #00000040",
      }}
    >
      <Grid size={12}>
        <Grid
          container
          size={{ xs: 12, md: 8, lg: 6 }}
          sx={{ textAlign: "center" }}
        >
          <ToggleButtonGroup
            sx={{ width: "100%", display: { sm: "flex", xs: "none" } }}
            value={paymentMethod}
            {...register}
            exclusive
            onChange={handleChoosePaymentMethod}
          >
            {paymentMethods.map((item, index) => (
              <ToggleButton key={index} value={item.value || ""}>
                <item.icon fontSize={"1rem"} />
                {item.key}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Box
            sx={{
              display: {
                sm: "none",
              },
              width: "100%",
            }}
          >
            <SelectBox
              label="Payment method"
              name="paymentMethod"
              value={paymentMethod}
              onChange={(e: any) => {
                setPaymentMethod(e.target.value);
              }}
              dropdown={paymentMethods}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        size={{ xs: 12, md: 12, lg: 6 }}
        sx={{
          textAlign: "center",
          py: 4,
          flexDirection: "column",
        }}
        gap={2}
      >
        <Grid container size={12}>
          <TextField
            label="Amount"
            name="amount"
            type="number"
            register={register}
            valueAsNumber={true}
            error={errors.amount}
            defaultValue={invoice?.balanceAmount}
            required
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              },
            }}
          />
        </Grid>
        <Grid container size={12}>
          <TextField
            label="Address Line 1"
            name="addressLine1"
            required
            register={register}
            error={errors.addressLine1}
            defaultValue={invoice?.address.addressLine1}
          />
        </Grid>
        <Grid container size={12}>
          <TextField
            label="Address Line 2 (Optional)"
            name="addressLine2"
            register={register}
            error={errors.addressLine2}
            defaultValue={invoice?.address.addressLine2}
          />
        </Grid>
        <Grid container size={12} direction={"row"} spacing={2}>
          <Grid size={{ sm: 4, xs: 12 }}>
            <TextField
              label="City"
              name="city"
              required
              register={register}
              error={errors.city}
              helperTextSx={{
                whiteSpace: "nowrap !important",
              }}
              defaultValue={invoice?.address.city}
            />
          </Grid>
          <Grid size={{ sm: 4, xs: 12 }}>
            <SelectBox
              key={invoice?.address.state || "default"}
              label="State"
              name="state"
              dropdown={STATES}
              required
              register={register}
              error={errors.state}
              helperTextSx={{
                whiteSpace: "nowrap !important",
              }}
              defaultValue={invoice?.address.state || ""}
            />
          </Grid>
          <Grid size={{ sm: 4, xs: 12 }}>
            <TextField
              type="number"
              label="Zip"
              name="zip"
              required
              register={register}
              error={errors.zip}
              helperTextSx={{
                whiteSpace: "nowrap !important",
              }}
              defaultValue={invoice?.address.zip}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        size={{ xs: 12, md: 12, lg: 6 }}
        sx={{
          textAlign: "center",
          py: 4,
          flexDirection: "column",
        }}
        gap={2}
      >
        <Grid
          container
          size={12}
          flexGrow={1}
          direction="column"
          justifyContent={"flex-end"}
          gap={1}
        >
          <Divider />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Wash Charges</Typography>
            <Typography>{invoice?.totalWashCharge || 0}</Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Miscellaneous Charges</Typography>
            <Typography>
              {(invoice?.totalMiscCharge ?? 0) +
                (invoice?.tripMinimumCharge ?? 0)}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Invoice Amount</Typography>
            <Typography>{invoice?.invoiceAmount || 0}</Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Amount Paid</Typography>
            <Typography>{invoice?.paidAmount || 0}</Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Amount Payable</Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
              {invoice?.balanceAmount || 0}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
