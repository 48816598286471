import { useMutation, useQuery } from "react-query";
import { config } from "../config";
import {
  ApiBasePathParams,
  BulkEditWashInventoryPayload,
  QueryOptions,
  WashesInfo,
  WashesRequestParams,
  WashItem,
} from "../types";
import { client } from "./axios";
import { DEFAULT_QUERY_OPTIONS } from "../constants";
import { formatDate } from "../utils";

const buildWashesUrl = (
  pathTemplate: string,
  washesRequestParams: WashesRequestParams,
  excludePagination: boolean = false
): string => {
  const { orgId, accountId, filters } = washesRequestParams;

  let path = pathTemplate
    .replace("{orgId}", orgId.toString())
    .replace("{accountId}", accountId.toString());

  const queryParams = new URLSearchParams(
    Object.entries(filters).reduce((params, [key, value]) => {
      if (value !== undefined && value !== null) {
        if (excludePagination && (key === "page" || key === "pageSize"))
          return params;
        params[key] = value.toString();
      }
      return params;
    }, {} as Record<string, string>)
  );

  if (queryParams.toString()) {
    path += `?${queryParams.toString()}`;
  }

  return path;
};

const buildUnbilledWashesUrl = (
  pathTemplate: string,
  reportRequestParams: WashesRequestParams
): string => {
  const { orgId, accountId, filters } = reportRequestParams;

  const fromDate = formatDate(
    new Date(filters.fromDate || "").setHours(0, 0, 0),
    "YYYY-MM-DD HH:mm:ss"
  );
  const toDate = formatDate(
    new Date(filters.toDate || "").setHours(23, 59, 59),
    "YYYY-MM-DD HH:mm:ss"
  );

  const path = pathTemplate
    .replace("{orgId}", orgId.toString())
    .replace("{accountId}", accountId.toString())
    .replace("{startDate}", fromDate)
    .replace("{endDate}", toDate);

  return path;
};

export const useFetchPaginatedWashesQuery = (
  washesRequestParams: WashesRequestParams,
  fetchOnMount: boolean = true
) => {
  const fetchWashes = async (): Promise<WashesInfo> => {
    const path = buildWashesUrl(
      config.api.washInventoryService.getAllWashes,
      washesRequestParams
    );
    const response = await client.get<any>(path);
    return response.data.washInventory;
  };
  return useQuery(["fetchWashes", washesRequestParams], fetchWashes, {
    retry: false,
    enabled: fetchOnMount,
  });
};

export const useFetchDownloadWashesCsvQuery = (
  washesRequestParams: WashesRequestParams,
  queryOptions?: QueryOptions<string>
) => {
  const downloadWashes = async (): Promise<string> => {
    const path = buildWashesUrl(
      config.api.washInventoryService.downloadWashesCsv,
      washesRequestParams,
      true
    );
    const response = await client.get<any>(path, {
      headers: {
        "Content-Type": "text/csv",
        Accept: "text/csv",
      },
    });
    return response.data;
  };
  return useQuery(["downloadWashes"], downloadWashes, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useFetchAllUnbilledWashInventoryQuery = (
  washesRequestParams: WashesRequestParams,
  fetchOnMount: boolean = true
) => {
  const fetchAllUnbilledWashInventory = async (): Promise<WashItem[]> => {
    const path = buildUnbilledWashesUrl(
      config.api.washInventoryService.getAllUnbilledWashInventory,
      washesRequestParams
    );
    const response = await client.get<any>(path);
    return response.data.washInventory;
  };

  return useQuery(
    ["fetchAllUnbilledWashInventory", washesRequestParams],
    fetchAllUnbilledWashInventory,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useUpdateWashInventoryMutation = () => {
  const mutation = useMutation(async (data: ApiBasePathParams & WashItem) => {
    const { id, orgId, accountId, locationNumber, ...washInventory } = data;
    const path = config.api.washInventoryService.updateWashInventory
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{locationNumber}", locationNumber.toString())
      .replace("{id}", id.toString());
    const response = await client.patch(path, washInventory);
    return response;
  });

  return mutation;
};

export const useDeleteWashInventoryMutation = () => {
  const mutation = useMutation(async (data: { orgId: number; id: number }) => {
    const path = config.api.washInventoryService.deleteWashInventory
      .replace("{orgId}", data.orgId.toString())
      .replace("{id}", data.id.toString());
    const response = await client.delete(path);
    return response;
  });
  return mutation;
};

export const useBulkUpdateWashInventoryMutation = () => {
  const mutation = useMutation(
    async (data: ApiBasePathParams & BulkEditWashInventoryPayload) => {
      const { orgId, accountId, ...washInventory } = data;
      const path = config.api.washInventoryService.bulkUpdateWashInventory
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString());
      const response = await client.patch(path, washInventory);
      return response;
    }
  );

  return mutation;
};

export const useDeleteWashInventoriesMutation = () => {
  const mutation = useMutation(
    async (data: { orgId: number; ids: number[] }) => {
      const { orgId, ...ids } = data;
      const path =
        config.api.washInventoryService.deleteWashInventories.replace(
          "{orgId}",
          orgId.toString()
        );
      const response = await client.delete(path, {
        data: ids,
      });
      return response;
    }
  );
  return mutation;
};
