import { useMutation } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import { ApiBasePathParams, ApiError } from "../types";
import { AxiosError } from "axios";

export const useFetchHostedPaymentsPageMutation = () => {
  const mutation = useMutation<
    string,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      invoiceNumber: string;
      amount: number;
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      zip: string;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        invoiceNumber: string;
        amount: number;
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        zip: string;
      }
    ) => {
      const { orgId, accountId, invoiceNumber, ...payload } = data;

      const path =
        config.api.worldpayIntegrationService.initiateWorldpayTransaction
          .replace("{orgId}", orgId.toString())
          .replace("{accountId}", accountId.toString())
          .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post(path, { ...payload });

      return response.data.hostedPaymentsPageUrl;
    }
  );

  return mutation;
};
