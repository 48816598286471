import { Box, Grid2 } from "@mui/material";
import { ActionIconsV2, DataGrid, Modal, Toast } from "../common";
import {
  ApiBasePathParams,
  BulkEditWashInventoryPayload,
  ToastOptions,
  UserPermission,
  WashesInfo,
  WashesRequestParams,
  WashItem,
} from "../../types";
import { downloadFile, theme } from "../../utils";
import { useAtom } from "jotai";
import {
  selectedAccountAtom,
  selectedOrgIdAtom,
  userAtom,
  userPermissionAtom,
  washInventoryDataGridPaginationAtom,
} from "../../atoms";
import { GridColDef, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate, isValidDate } from "../../utils/dateUtil";
import { washInventoryFiltersAtom } from "../../atoms/washInventoryAtoms";
import {
  useBulkUpdateWashInventoryMutation,
  useDeleteWashInventoriesMutation,
  useDeleteWashInventoryMutation,
  useFetchDownloadWashesCsvQuery,
  useFetchPaginatedWashesQuery,
  useUpdateWashInventoryMutation,
} from "../../api/washInventoryService";
import { Action, Module, Role } from "../../enums";
import WashInventoryToolBar from "./fragments/washInventoryToolBar";
import { WashInventoryForm } from "./fragments";
import { useHasPermission } from "@zdistancelab-packages/ui-permission-validator";
import { queryClient } from "../../App";

export const WashInventory = () => {
  const [user] = useAtom(userAtom);
  const [userPermission] = useAtom<UserPermission>(userPermissionAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccount] = useAtom(selectedAccountAtom);
  const [washInventoryList, setWashInventoryList] = useState<WashesInfo>();
  const [washInventoryPaginationModel, setWashInventoryPaginationModel] =
    useAtom(washInventoryDataGridPaginationAtom);
  const [selectedWashInventories, setSelectedWashInventories] = useState<
    number[]
  >([]);
  const [washInventoryFilters, setWashInventoryFilters] = useAtom(
    washInventoryFiltersAtom
  );
  const hasWritePermission = useHasPermission(userPermission, [
    { module: Module.WashInventory, action: Action.Write },
  ]);
  const hasDeletePermission = useHasPermission(userPermission, [
    { module: Module.WashInventory, action: Action.Delete },
  ]);
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const updateWashInventoryMutation = useUpdateWashInventoryMutation();
  const bulkUpdateWashInventoryMutation = useBulkUpdateWashInventoryMutation();
  const deleteWashInventoryMutation = useDeleteWashInventoryMutation();
  const deleteWashInventoriesMutation = useDeleteWashInventoriesMutation();
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
  const handleOpenBulkEditModal = () => setIsBulkEditModalOpen(true);
  const handleCloseBulkEditModal = () => setIsBulkEditModalOpen(false);
  const [isAddWashModalOpen, setIsAddWashModalOpen] = useState(false);
  const handleCloseWashModal = () => setIsAddWashModalOpen(false);
  const handleCreateWash = (data: any) => {
    console.log("Data: ", data);
  };
  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      const sortField = sortModel[0].field;
      const sortOrder: GridSortDirection = sortModel[0].sort;
      if (sortOrder) {
        setWashInventoryFilters({
          ...washInventoryFilters,
          sortBy: sortField,
          sortOrder: sortOrder,
        });
      }
    } else {
      setWashInventoryFilters({
        ...washInventoryFilters,
        sortBy: "",
        sortOrder: "",
      });
    }
  };
  const buildWashesRequestParams = (): WashesRequestParams => {
    return {
      orgId: selectedOrgId,
      accountId:
        user?.roleCode === Role.SuperAdmin || user?.roleCode === Role.Admin
          ? washInventoryFilters?.accountId || 0
          : selectedAccount?.accountId || 0,
      filters: {
        page: washInventoryFilters?.page ? washInventoryFilters?.page + 1 : 1,
        pageSize: washInventoryFilters?.pageSize,
        fromDate: washInventoryFilters?.fromDate,
        toDate: washInventoryFilters?.toDate,
        sortBy: washInventoryFilters?.sortBy,
        sortOrder: washInventoryFilters?.sortOrder,
        washTypeId: washInventoryFilters?.washTypeId,
        userId: washInventoryFilters?.userId,
        vehicleNumber: washInventoryFilters?.vehicleNumber,
      },
    };
  };

  const fetchWashInventoryQuery = useFetchPaginatedWashesQuery(
    buildWashesRequestParams(),
    selectedOrgId ? true : false
  );

  const downloadWashInventoryQuery = useFetchDownloadWashesCsvQuery(
    buildWashesRequestParams(),
    { enabled: false }
  );
  const handleEditWashInventory = (data: WashItem) => {
    const washItem: WashItem | undefined = washInventoryList?.items.find(
      (item) => item.id === data.id
    );
    if (washItem) {
      updateWashInventoryMutation.mutate({
        ...washItem,
        orgId: washItem.orgId,
        teamId: user?.teamId || 0,
        comments: "Manually updated from Sprayzapp.",
        endTime:
          formatDate(washItem.endTime, "MM/DD/YYYY") !== data.endTime
            ? formatDate(data.endTime, "YYYY-MM-DD HH:mm:ss")
            : washItem.endTime,
        startTime:
          formatDate(washItem.endTime, "MM/DD/YYYY") !== data.startTime
            ? formatDate(data.endTime, "YYYY-MM-DD HH:mm:ss")
            : washItem.endTime,
        vehicleNumber: data.vehicleNumber,
        leadId: data.leadId,
        washTypeId: data.washTypeId,
      });
    }
  };

  const handleDeleteWashInventory = (data: WashItem) => {
    deleteWashInventoryMutation.mutate({
      orgId: data.orgId,
      id: data.id,
    });
  };
  const columns: GridColDef[] = [
    {
      field: "endTime",
      headerName: "Date",
      width: 150,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "MM/DD/YYYY");
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      minWidth: 200,
      editable: false,
    },
    {
      field: "leadName",
      headerName: "Lead",
      width: 170,
      editable: false,
    },
    {
      field: "washTypeName",
      headerName: "Wash Type",
      width: 150,
      editable: false,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 80,
      editable: false,
    },
    {
      field: "vehicleNumber",
      headerName: "Vehicle Number",
      width: 150,
      editable: false,
    },
  ];

  if (hasWritePermission || hasDeletePermission) {
    columns.push({
      field: "Actions",
      headerName: "Actions",
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <ActionIconsV2
          onEdit={handleEditWashInventory}
          onDelete={handleDeleteWashInventory}
          hasWritePermission={hasWritePermission}
          hasDeletePermission={hasDeletePermission}
          rowData={params.row}
          formProps={{
            form: WashInventoryForm,
            name: "editWashInventoryForm",
          }}
          editProps={{
            title: "Edit Wash",
            primaryButtonText: "Save",
          }}
          deleteProps={{
            title: "Delete Wash",
          }}
        />
      ),
    });
  }

  const handleRowSelectionChange = (selectedRowIds: number[]) => {
    setSelectedWashInventories(selectedRowIds);
  };

  const handleRefresh = () => {
    fetchWashInventoryQuery.refetch();
  };
  const handleDownload = () => {
    downloadWashInventoryQuery.refetch();
  };

  useEffect(() => {
    if (
      downloadWashInventoryQuery.isSuccess &&
      downloadWashInventoryQuery.data
    ) {
      const accountName = selectedAccount ? selectedAccount.accountName : "";
      const fileName =
        [
          user?.roleCode === Role.SuperAdmin ? "" : accountName || "unknown",
          washInventoryFilters.fromDate || "",
          washInventoryFilters.fromDate && washInventoryFilters.toDate
            ? "to"
            : "",
          washInventoryFilters.toDate || "",
        ]
          .filter(Boolean)
          .join("_") + "_wash-inventory.csv";
      const blob = new Blob([downloadWashInventoryQuery.data], {
        type: "text/csv",
      });
      const linkToCsv = URL.createObjectURL(blob);
      if (linkToCsv) downloadFile(linkToCsv, fileName);

      queryClient.resetQueries("downloadWashes");
    }
  }, [
    downloadWashInventoryQuery.isSuccess,
    downloadWashInventoryQuery.data,
    downloadWashInventoryQuery.dataUpdatedAt,
  ]);

  useEffect(() => {
    if (fetchWashInventoryQuery.isSuccess) {
      setWashInventoryList(fetchWashInventoryQuery.data);
    }
  }, [fetchWashInventoryQuery.isSuccess, fetchWashInventoryQuery.data]);

  useEffect(() => {
    setWashInventoryFilters({
      ...washInventoryFilters,
      page: washInventoryPaginationModel.page,
      pageSize: washInventoryPaginationModel.pageSize,
    });
  }, [washInventoryPaginationModel]);

  useEffect(() => {
    if (deleteWashInventoryMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Wash inventory deleted successfully.",
        severity: "success",
      });
      fetchWashInventoryQuery.refetch();
    } else if (deleteWashInventoryMutation.isError) {
      setToastOptions({
        open: true,
        message: "Wash inventory delete failed",
        severity: "error",
      });
    }
  }, [
    deleteWashInventoryMutation.isLoading,
    deleteWashInventoryMutation.isSuccess,
    deleteWashInventoryMutation.isError,
  ]);

  useEffect(() => {
    if (deleteWashInventoriesMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Wash inventories deleted successfully.",
        severity: "success",
      });
      fetchWashInventoryQuery.refetch();
    } else if (deleteWashInventoriesMutation.isError) {
      setToastOptions({
        open: true,
        message: "Wash inventories delete failed",
        severity: "error",
      });
    }
  }, [
    deleteWashInventoriesMutation.isLoading,
    deleteWashInventoriesMutation.isSuccess,
    deleteWashInventoriesMutation.isError,
  ]);

  useEffect(() => {
    if (updateWashInventoryMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Wash inventory updated",
        severity: "success",
      });
      fetchWashInventoryQuery.refetch();
    } else if (updateWashInventoryMutation.isError) {
      setToastOptions({
        open: true,
        message: "Wash inventory update failed",
        severity: "error",
      });
    }
  }, [
    updateWashInventoryMutation.isLoading,
    updateWashInventoryMutation.isSuccess,
    updateWashInventoryMutation.isError,
  ]);

  useEffect(() => {
    if (bulkUpdateWashInventoryMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Wash inventories updated",
        severity: "success",
      });
      fetchWashInventoryQuery.refetch();
    } else if (bulkUpdateWashInventoryMutation.isError) {
      setToastOptions({
        open: true,
        message: "Wash inventories update failed",
        severity: "error",
      });
    }
  }, [
    bulkUpdateWashInventoryMutation.isLoading,
    bulkUpdateWashInventoryMutation.isSuccess,
    bulkUpdateWashInventoryMutation.isError,
  ]);

  const handleBulkUpdateButtonClick = () => {
    handleOpenBulkEditModal();
  };

  const handleAddButtonClick = () => {
    setIsAddWashModalOpen(true);
  };

  const handleRemoveButtonClick = () => {
    deleteWashInventoriesMutation.mutate({
      orgId: selectedOrgId,
      ids: selectedWashInventories,
    });
  };

  const handleBulkEdit = (data: any) => {
    const washInventoryData: ApiBasePathParams & BulkEditWashInventoryPayload =
      {
        orgId: selectedOrgId,
        accountId: 0,
        ids: selectedWashInventories,
        ...(data.washTypeId && { washTypeId: data.washTypeId }),
        ...(data.vehicleNumber && { vehicleNumber: data.vehicleNumber }),
        ...(data.leadId && { leadId: data.leadId }),
        ...(isValidDate(data.endTime) && {
          endTime: formatDate(data.endTime, "YYYY-MM-DD HH:mm:ss"),
          bulkEditDate: formatDate(data.endTime, "YYYY-MM-DD HH:mm:ss"),
        }),
      };
    bulkUpdateWashInventoryMutation.mutate(washInventoryData);
    handleCloseBulkEditModal();
  };

  return (
    <Box
      sx={{
        marginTop: "50px",
        width: "100%",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <Toast setOptions={setToastOptions} options={toastOptions} />
      <Box>
        <Grid2
          container
          direction={"column"}
          spacing={2}
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <WashInventoryToolBar
            onBulkEdit={handleBulkUpdateButtonClick}
            onRefresh={handleRefresh}
            onDownload={handleDownload}
            disableBulkEditBtn={
              selectedWashInventories.length > 1 ? false : true
            }
          />
        </Grid2>
        <Grid2 container direction={"column"} spacing={2}>
          <DataGrid
            columns={columns}
            rows={washInventoryList?.items || []}
            loading={
              fetchWashInventoryQuery.isLoading ||
              fetchWashInventoryQuery.isRefetching ||
              downloadWashInventoryQuery.isLoading ||
              downloadWashInventoryQuery.isRefetching ||
              updateWashInventoryMutation.isLoading ||
              deleteWashInventoryMutation.isLoading ||
              deleteWashInventoriesMutation.isLoading
            }
            onSortModelChange={handleSortModelChange}
            paginationModel={washInventoryPaginationModel}
            setPaginationModel={setWashInventoryPaginationModel}
            onSelectedRowIdsChange={handleRowSelectionChange}
            totalRecords={washInventoryList?.totalItems}
            paginationMode="server"
            rowCount={
              washInventoryList?.totalItems ? washInventoryList?.totalItems : 0
            }
            pageSize={washInventoryList?.pageSize}
            sx={{
              border: "none",
              backgroundColor: theme.palette.secondary.light,
              padding: "20px",
              paddingTop: "0px",
              "& .MuiSvgIcon-root": {
                fontSize: 16,
              },
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              height: "70vh",
            }}
            footerProps={{
              addBtnLabel: hasWritePermission ? "Add" : "",
              hasAddIcon: true,
              hasRemoveIcon: true,
              removeBtnLabel: hasDeletePermission ? "Delete" : "",
              onAddButtonClick: handleAddButtonClick,
              handleConfirmRemove: handleRemoveButtonClick,
              disableRemoveBtn:
                selectedWashInventories.length > 0 ? false : true,
              confirmationModalTitle: "Delete Washes",
              confirmationModalMessage:
                selectedWashInventories.length == 1
                  ? "Are you sure you want to delete this wash?"
                  : `Are you sure you want to delete these ${selectedWashInventories.length} washes?`,
            }}
          />
        </Grid2>
        <Modal
          open={isBulkEditModalOpen}
          onClose={handleCloseBulkEditModal}
          title="Bulk Edit"
          content={
            <WashInventoryForm
              id={"bulkEditForm"}
              handleEdit={handleBulkEdit}
              defaultValues={undefined}
              isBulkEdit={true}
            />
          }
          form={"bulkEditForm"}
          primaryButtonText="Confirm"
          secondaryButtonText="Cancel"
          onSecondaryButtonClick={handleCloseBulkEditModal}
          sx={{
            modal: {
              width: { lg: "30%", xs: "80%", sm: "50%" },
            },
          }}
        />
      </Box>
      <Modal
        open={isAddWashModalOpen}
        onClose={handleCloseWashModal}
        title={"Add Wash Inventory"}
        content={
          <WashInventoryForm
            id={"addWashInventoryForm"}
            handleEdit={handleCreateWash}
          ></WashInventoryForm>
        }
        form={"addWashInventoryForm"}
        primaryButtonText={"Save"}
        secondaryButtonText={"Cancel"}
        onSecondaryButtonClick={handleCloseWashModal}
        sx={{
          modal: {
            width: { lg: "30%", xs: "80%", sm: "50%" },
          },
        }}
      />
    </Box>
  );
};
