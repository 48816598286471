import { Box, Grid2 } from "@mui/material";
import { Button, DataGrid } from "../common";
import { Report, ReportFilters, ReportRequestParams } from "../../types";
import {
  cleanString,
  convertToCSV,
  downloadFile,
  exportBlob,
  theme,
} from "../../utils";
import { useAtom } from "jotai";
import {
  reportFilterStateAtom,
  reportsDataGridPaginationAtom,
  selectedAccountAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
  userAtom,
} from "../../atoms";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate, getDateRange } from "../../utils/dateUtil";
import {
  ReportFiltersAtom,
  reportSelectedDateRangeAtom,
} from "../../atoms/reportAtoms";
import ReportsToolBar from "./fragments/ReportsToolBar";
import {
  useFetchAccountActivityReportQuery,
  useFetchWashDetailsReportQuery,
  useFetchLeadReportQuery,
  useFetchDownloadWashDetailsReportQuery,
} from "../../api";
import { Role, ReportType, StatusCodes } from "../../enums";
import { ReportModel } from "../../models";

type ReportProps = {
  reportType: ReportType;
};

export const Reports = ({ reportType }: ReportProps) => {
  const [user] = useAtom(userAtom);
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccount] = useAtom(selectedAccountAtom);
  const [reportList, setReportList] = useState<Report[]>([]);
  const [reportsPaginationModel, setReportsPaginationModel] = useAtom(
    reportsDataGridPaginationAtom
  );
  const [selectedRange, setSelectedRange] = useAtom(
    reportSelectedDateRangeAtom
  );
  const [filterValues, setFilterValues] = useAtom(reportFilterStateAtom);
  const currentDate = getDateRange("thisMonth");
  const [selectedReports, setSelectedReports] = useState<number[]>([]);
  const [linkToPdfData, setLinkToPdfData] = useState<string>("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [reportFilters, setReportFilters] = useState<ReportFilters>({
    fromDate: currentDate.startDate,
    toDate: currentDate.endDate,
  });
  const [, setToastOptions] = useAtom(toastOptionsAtom);

  const resetPage = () => {
    setReportsPaginationModel({ ...reportsPaginationModel, page: 0 });
  };

  const buildReportRequestParams = (): ReportRequestParams => {
    return {
      orgId: selectedOrgId,
      accountId:
        user?.roleCode === Role.SuperAdmin || user?.roleCode === Role.Admin
          ? reportFilters?.accountId || 0
          : selectedAccount?.accountId || 0,
      filters: {
        fromDate: reportFilters?.fromDate,
        toDate: reportFilters?.toDate,
        locationId: reportFilters?.locationId,
      },
    };
  };

  const fetchAccountActivityReportQuery = useFetchAccountActivityReportQuery(
    buildReportRequestParams(),
    selectedOrgId
      ? reportType === ReportType.AccountActivity
        ? true
        : false
      : false
  );

  const fetchWashDetailsReportQuery = useFetchWashDetailsReportQuery(
    buildReportRequestParams(),
    selectedOrgId
      ? reportType === ReportType.WashDetails
        ? true
        : false
      : false
  );

  const fetchDownloadWashDetailsReportQuery =
    useFetchDownloadWashDetailsReportQuery(buildReportRequestParams(), false);

  const fetchLeadReportQuery = useFetchLeadReportQuery(
    buildReportRequestParams(),
    selectedOrgId ? (reportType === ReportType.Lead ? true : false) : false
  );

  const accountActivityReportColumns: GridColDef[] = [
    {
      field: "endTime",
      headerName: "Date",
      width: 210,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      minWidth: 200,
      editable: false,
    },
    {
      field: "name",
      headerName: "Wash Type",
      width: 150,
      editable: false,
    },
    {
      field: "numberOfWash",
      headerName: "Quantity",
      width: 170,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
  ];

  const leadReportColumns: GridColDef[] = [
    {
      field: "leadName",
      headerName: "Lead Name",
      minWidth: 200,
      editable: false,
    },
    {
      field: "washDate",
      headerName: "Date of washing",
      width: 210,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "name",
      headerName: "Wash Type",
      width: 150,
      editable: false,
    },
    {
      field: "numberOfWash",
      headerName: "Quantity",
      width: 170,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
  ];

  const washDetailsReportColumns: GridColDef[] = [
    {
      field: "washDate",
      headerName: "Date",
      width: 210,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      minWidth: 200,
      editable: false,
    },
    {
      field: "leadName",
      headerName: "Lead",
      width: 170,
      editable: false,
    },
    {
      field: "washTypeName",
      headerName: "Wash Type",
      width: 140,
      editable: false,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 170,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      field: "vehicleNumber",
      headerName: "Fleet Number",
      width: 170,
      editable: false,
    },
  ];

  const handleRowSelectionChange = (selectedRowIds: number[]) => {
    setSelectedReports(selectedRowIds);
  };

  const handleDownload = () => {
    const csvData = convertToCSV(
      reportType === ReportType.AccountActivity
        ? accountActivityReportColumns
        : reportType === ReportType.WashDetails
        ? washDetailsReportColumns
        : leadReportColumns,
      reportList,
      []
    );
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const reportName = reportType;
    const accountName = selectedAccount ? selectedAccount.accountName : "";
    const fromDate = reportFilters.fromDate ? reportFilters.fromDate : "";
    const toDate = reportFilters.toDate ? reportFilters.toDate : "";
    const filename =
      cleanString(reportName) +
      "_report_" +
      cleanString(accountName) +
      "_" +
      cleanString(fromDate) +
      "-" +
      cleanString(toDate) +
      ".csv";
    exportBlob(blob, filename);
    setToastOptions({
      open: true,
      message: "Report exported successfully!",
      severity: "success",
    });
  };

  const getWashDetailsReport = () => {
    setIsDownloading(true);
    fetchDownloadWashDetailsReportQuery.refetch();
  };

  useEffect(() => {
    if (fetchAccountActivityReportQuery.isSuccess) {
      const reports = ReportModel.buildAccountActivityReport(
        fetchAccountActivityReportQuery.data
      );
      if (reportType === ReportType.AccountActivity) {
        setReportList(reports);
      }
    }

    if (fetchAccountActivityReportQuery.isError) {
      const error = fetchAccountActivityReportQuery.error as { status: number };
      if (error.status !== StatusCodes.NotFound) {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
      if (reportType === ReportType.AccountActivity) {
        setReportList([]);
      }
    }
  }, [
    fetchAccountActivityReportQuery.isSuccess,
    fetchAccountActivityReportQuery.data,
    fetchAccountActivityReportQuery.isError,
    fetchAccountActivityReportQuery.isLoading,
    fetchAccountActivityReportQuery.isFetching,
    fetchAccountActivityReportQuery.isFetched,
    fetchAccountActivityReportQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchWashDetailsReportQuery.isSuccess) {
      if (reportType === ReportType.WashDetails) {
        setReportList(fetchWashDetailsReportQuery.data);
      }
    }

    if (fetchWashDetailsReportQuery.isError) {
      const error = fetchWashDetailsReportQuery.error as { status: number };
      if (error.status !== StatusCodes.NotFound) {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
      if (reportType === ReportType.WashDetails) {
        setReportList([]);
      }
    }
  }, [
    fetchWashDetailsReportQuery.isSuccess,
    fetchWashDetailsReportQuery.data,
    fetchWashDetailsReportQuery.isError,
    fetchWashDetailsReportQuery.isLoading,
    fetchWashDetailsReportQuery.isFetching,
    fetchWashDetailsReportQuery.isFetched,
    fetchWashDetailsReportQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchLeadReportQuery.isSuccess) {
      const reports = ReportModel.buildLeadReport(fetchLeadReportQuery.data);
      if (reportType === ReportType.Lead) {
        setReportList(reports);
      }
    }

    if (fetchLeadReportQuery.isError) {
      const error = fetchLeadReportQuery.error as { status: number };
      if (error.status !== StatusCodes.NotFound) {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
      if (reportType === ReportType.Lead) {
        setReportList([]);
      }
    }
  }, [
    fetchLeadReportQuery.isSuccess,
    fetchLeadReportQuery.data,
    fetchLeadReportQuery.isError,
    fetchLeadReportQuery.isLoading,
    fetchLeadReportQuery.isFetching,
    fetchLeadReportQuery.isFetched,
    fetchLeadReportQuery.isRefetching,
  ]);

  useEffect(() => {
    if (
      fetchDownloadWashDetailsReportQuery.isSuccess &&
      fetchDownloadWashDetailsReportQuery.data
    ) {
      const dataToArrayBuffer = new Uint8Array(
        fetchDownloadWashDetailsReportQuery.data
      );
      const linkToPdf = URL.createObjectURL(
        new Blob([dataToArrayBuffer], {
          type: "application/pdf",
        })
      );
      setLinkToPdfData(linkToPdf);
      if (isDownloading) {
        downloadFile(
          linkToPdf,
          "wash-details-" +
            reportFilters.accountId +
            "-" +
            reportFilters.fromDate +
            "-to-" +
            reportFilters.toDate +
            ".pdf"
        );
        setIsDownloading(false);
      }
    }

    if (fetchDownloadWashDetailsReportQuery.isError) {
      const error = fetchDownloadWashDetailsReportQuery.error as {
        status: number;
      };
      if (error.status !== StatusCodes.NotFound) {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    fetchDownloadWashDetailsReportQuery.isSuccess,
    fetchDownloadWashDetailsReportQuery.data,
    fetchDownloadWashDetailsReportQuery.isError,
    fetchDownloadWashDetailsReportQuery.isLoading,
    fetchDownloadWashDetailsReportQuery.isFetching,
    fetchDownloadWashDetailsReportQuery.isFetched,
    fetchDownloadWashDetailsReportQuery.isRefetching,
  ]);

  useEffect(() => {
    setReportList([]);
    setSelectedOrgId(
      selectedOrgId !== 0 ? selectedOrgId : user?.orgId ? user?.orgId : 0
    );
    switch (reportType) {
      case ReportType.AccountActivity:
        fetchAccountActivityReportQuery.refetch();
        break;
      case ReportType.WashDetails:
        fetchWashDetailsReportQuery.refetch();
        break;
      case ReportType.Lead:
        fetchLeadReportQuery.refetch();
        break;
    }
  }, [reportType, reportFilters]);

  useEffect(() => {
    setReportFilters({
      fromDate: currentDate.startDate,
      toDate: currentDate.endDate,
    });
    setSelectedRange("thisMonth");
  }, [reportType]);

  useEffect(() => {
    resetPage();
  }, [reportList]);

  return (
    <Box
      sx={{
        marginTop: "50px",
        width: "100%",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <Box>
        <Grid2
          container
          direction={"column"}
          spacing={2}
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <ReportsToolBar
            onDownload={handleDownload}
            onGetWashDetailsReport={getWashDetailsReport}
            reportType={reportType}
            reportFilters={reportFilters}
            setReportFilters={setReportFilters}
          />
        </Grid2>
        <Grid2 container direction={"column"} spacing={2}>
          <DataGrid
            columns={
              reportType === ReportType.AccountActivity
                ? accountActivityReportColumns
                : reportType === ReportType.WashDetails
                ? washDetailsReportColumns
                : leadReportColumns
            }
            rows={reportList || []}
            loading={
              fetchAccountActivityReportQuery.isLoading ||
              fetchAccountActivityReportQuery.isRefetching ||
              fetchWashDetailsReportQuery.isLoading ||
              fetchWashDetailsReportQuery.isRefetching ||
              fetchLeadReportQuery.isLoading ||
              fetchLeadReportQuery.isRefetching
            }
            paginationModel={reportsPaginationModel}
            setPaginationModel={setReportsPaginationModel}
            onSelectedRowIdsChange={handleRowSelectionChange}
            sx={{
              border: "none",
              backgroundColor: theme.palette.secondary.light,
              padding: "20px",
              paddingTop: "0px",
              "& .MuiSvgIcon-root": {
                fontSize: 16,
              },
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              height: "70vh",
            }}
          />
        </Grid2>
      </Box>
    </Box>
  );
};
