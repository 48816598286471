import { useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, Button, DialogTitle, IconButton } from "@mui/material";
import { HorizontalStepper } from "../common";
import { FaMoneyCheckAlt, FaCreditCard, FaMoneyBillWave } from "react-icons/fa";
import { RiBankFill } from "react-icons/ri";
import { InvoicePaymentCallbackMessage, PaymentMethod } from "../../enums";
import { DropdownItem, InvoiceOverviewInfo, PaymentDetails } from "../../types";
import { IconType } from "react-icons";
import { DetailsForm, HostedPaymentsPage } from "./fragments";
import { useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useValidateForm } from "../../hooks";
import { getPaymentDetailsSchema } from "../../validations";

export const paymentMethods: (DropdownItem & { icon: IconType })[] = [
  { key: "Card", value: PaymentMethod.Card, icon: FaCreditCard },
  { key: "Check", value: PaymentMethod.Check, icon: FaMoneyCheckAlt },
  { key: "ACH", value: PaymentMethod.Ach, icon: RiBankFill },
  { key: "Cash", value: PaymentMethod.Cash, icon: FaMoneyBillWave },
];

type PaymentDialogProps = {
  invoiceOverview: InvoiceOverviewInfo;
  open: boolean;
  handleClose: (message: InvoicePaymentCallbackMessage) => void;
};

export const PaymentDialog = ({
  invoiceOverview,
  open,
  handleClose,
}: PaymentDialogProps) => {
  const [callbackMessage, setCallbackMessage] = useState(
    InvoicePaymentCallbackMessage.None
  );
  const [activeStep, setActiveStep] = useState(1);
  const [queryParams] = useSearchParams();
  const orgId = Number(queryParams.get("orgId")) || 0;
  const [updatedInvoiceOverview, setUpdatedInvoiceOverview] =
    useState<InvoiceOverviewInfo>(invoiceOverview);

  const schema = useMemo(() => {
    return getPaymentDetailsSchema(invoiceOverview?.balanceAmount);
  }, [invoiceOverview]);

  const { register, handleSubmit, errors, reset, setValue } =
    useValidateForm(schema);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (
        (event.data.status as InvoicePaymentCallbackMessage) ===
        InvoicePaymentCallbackMessage.PaymentSuccess
      ) {
        setCallbackMessage(InvoicePaymentCallbackMessage.PaymentSuccess);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleProceed = (data: PaymentDetails) => {
    if (invoiceOverview) {
      setUpdatedInvoiceOverview({
        ...invoiceOverview,
        address: {
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          city: data.city,
          state: data.state,
          zip: data.zip,
        },
        invoiceAmount: data.amount,
        paymentMethod: data.paymentMethod,
      });
    }

    if (activeStep < 2) setActiveStep(activeStep + 1);
  };

  const handleCloseWrapper = () => {
    setActiveStep(1);
    reset();
    handleClose(callbackMessage);
    setCallbackMessage(InvoicePaymentCallbackMessage.None);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseWrapper}
      fullWidth={true}
      maxWidth={"lg"}
      sx={{}}
    >
      <Box sx={{ pt: 4, pb: 4, overflow: "auto" }}>
        <DialogTitle>
          <HorizontalStepper
            steps={["Enter Details", "Make Payment"]}
            activeStep={activeStep}
          />
          <IconButton
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              border: "none",
              padding: "8px 12px",
              fontSize: "14px",
              fontWeight: "bold",
              borderRadius: "4px",
              cursor: "pointer",
              zIndex: 1000,
            }}
            onClick={handleCloseWrapper}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {activeStep === 1 ? (
            <Box
              component={"form"}
              noValidate
              id={"paymentDetailsForm"}
              onSubmit={handleSubmit(handleProceed)}
            >
              <DetailsForm
                register={register}
                errors={errors}
                invoice={invoiceOverview}
                setValue={setValue}
              />
            </Box>
          ) : (
            <HostedPaymentsPage
              invoiceOverview={updatedInvoiceOverview}
              orgId={orgId}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          {activeStep === 1 && (
            <>
              <Button variant="outlined" onClick={handleCloseWrapper}>
                Cancel
              </Button>
              <Button
                type="submit"
                form="paymentDetailsForm"
                variant="contained"
              >
                Proceed
              </Button>
            </>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};
