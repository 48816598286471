import { Badge, Box, Grid2, Popover } from "@mui/material";
import { Button, DateRangePicker, SelectBox } from "../../common";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import {
  DateRange,
  DropdownItem,
  FilterItem,
  InvoiceFilters,
  InvoiceItem,
} from "../../../types";
import { Action, InvoiceStatus, Module, Role } from "../../../enums";
import { invoiceFilterStateAtom, userAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { getDateRange } from "../../../utils/dateUtil";
import {
  CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN,
  DATE_RANGE_OPTIONS,
  INVOICE_STATUS_TARGET_ACTIONS_ADMIN,
} from "../../../constants";
import { Moment } from "moment";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";
import { Filter } from "../../common/filter";
import { theme } from "../../../utils";
import { MdOutlineFilterAlt } from "react-icons/md";
import { InvoiceActions } from "./InvoiceActions";

type InvoiceToolbarProps = {
  invoices: InvoiceItem[];
  invoiceFilters: InvoiceFilters;
  setInvoiceFilters: React.Dispatch<React.SetStateAction<InvoiceFilters>>;
  onRefresh?: () => void;
  onDownload?: () => void;
  onCreateInvoice?: () => void;
};

export const InvoiceToolbar = ({
  invoices,
  invoiceFilters,
  setInvoiceFilters,
  onRefresh,
  onDownload,
  onCreateInvoice,
}: InvoiceToolbarProps) => {
  const [invoiceNumbers, setInvoiceNumbers] = useState<string[]>([]);
  const [user] = useAtom(userAtom);
  const [invoiceStatusDropDown, setInvoiceStatusDropDown] = useState<
    DropdownItem[]
  >([]);
  const [filterValues, setFilterValues] = useAtom(invoiceFilterStateAtom);
  const [selectedRange, setSelectedRange] = useState<string>("thisMonth");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFilterOpen = Boolean(anchorEl);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(
    Object.keys(filterValues).length || 0
  );
  const dateRanges: DropdownItem[] = [
    { key: "Select", value: "select" },
    ...DATE_RANGE_OPTIONS.map((option) => ({
      key: option,
      value: option.replace(/\s+/g, "").replace(/^\w/, (c) => c.toLowerCase()),
    })),
  ];

  const filters: FilterItem[] = [
    {
      type: "list",
      label: "Status",
      keyword: "status",
      values: invoiceStatusDropDown,
    },
  ];
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleApplyFilters = () => {
    setAppliedFiltersCount(Object.keys(filterValues).length);
    const appliedFilters: InvoiceFilters = { ...invoiceFilters };
    filters.forEach((filter) => {
      const filterKeyword = filter.keyword as keyof InvoiceFilters;
      const filterValue = filterValues[filter.keyword];
      if (filterValue !== undefined && filterValue !== null) {
        appliedFilters[filterKeyword] = filterValue;
      } else {
        delete appliedFilters[filterKeyword];
      }
    });
    setInvoiceFilters(appliedFilters);
    handleFilterClose();
  };

  const generateInvoiceStatusDropdownItems = (): DropdownItem[] => {
    if (user?.roleCode === Role.Customer) {
      return CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN;
    }

    return Object.values(InvoiceStatus)
      .filter((status) => status !== InvoiceStatus.SendToQuickbooks)
      .map((status) => ({
        key: status
          .replace(/-/g, " ")
          .replace(/^./, (char) => char.toUpperCase()),
        value: status,
      }));
  };

  const handleDateChange = (selectedDateRange: string) => {
    setSelectedRange(selectedDateRange);
    if (selectedDateRange !== "customDate") {
      const dateRange: DateRange = getDateRange(selectedDateRange);
      setInvoiceFilters({
        ...invoiceFilters,
        fromDate: dateRange.startDate.toString(),
        toDate: dateRange.endDate.toString(),
      });
    }
  };

  const handleCustomDateChange = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    if (startDate && endDate)
      setInvoiceFilters({
        ...invoiceFilters,
        fromDate: startDate?.format("YYYY-MM-DD"),
        toDate: endDate?.format("YYYY-MM-DD"),
      });
  };

  useEffect(() => {
    if (user) {
      const dropdownItems = generateInvoiceStatusDropdownItems();
      setInvoiceStatusDropDown(dropdownItems);
      setInvoiceFilters({
        ...invoiceFilters,
        status: dropdownItems[0]?.value
          ? dropdownItems[0]?.value.toString()
          : InvoiceStatus.Pending,
      });
    }
  }, [user]);

  useEffect(() => {
    handleApplyFilters();
    if (user?.roleCode === Role.Customer) {
      const filterValues = {
        status: CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN[0].value,
      };
      setFilterValues(filterValues);
      setInvoiceFilters((prev) => ({
        ...prev,
        filterValues,
      }));
      setAppliedFiltersCount(Object.keys(filterValues).length);
    }
    return () => {
      setFilterValues({});
    };
  }, []);

  useEffect(() => {
    const invoiceNumbers = invoices.map((e) => e.invoiceNumber);
    setInvoiceNumbers(invoiceNumbers);
  }, [invoices]);

  const hasSameInvoiceStatus = (arr: InvoiceItem[]): boolean => {
    if (arr.length === 0) return false;

    const firstStatus = arr[0].invoiceStatus;

    return arr.every((item) => item.invoiceStatus === firstStatus);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Grid2
          pl={1}
          size={{ lg: 6, md: 7, xs: 12 }}
          container
          alignItems="flex-end"
          justifyContent={"flex-start"}
        >
          <Grid2 size={{ lg: 4.5, sm: 6, xs: 12 }}>
            <SelectBox
              label="Date"
              name="date"
              dropdown={dateRanges}
              value={selectedRange}
              onChange={(e) => handleDateChange(e.target.value)}
              sx={{ width: "100%", height: "33px" }}
            />
          </Grid2>
          <AllowedAccess
            roles={[Role.SuperAdmin, Role.Admin]}
            permissions={[{ module: Module.Invoice, action: Action.Write }]}
          >
            <Grid2 size={{ lg: 4.5, sm: 6, xs: 12 }}>
              <Box>
                <InvoiceActions
                  disabled={!hasSameInvoiceStatus(invoices)}
                  status={
                    hasSameInvoiceStatus(invoices)
                      ? invoices[0].invoiceStatus
                      : InvoiceStatus.All
                  }
                  invoiceNumbers={invoiceNumbers}
                  orgId={invoices.length === 1 ? invoices[0].orgId : 0}
                  accountId={invoices.length === 1 ? invoices[0].accountId : 0}
                  handleRefresh={onRefresh}
                  invoiceStatusTargetActions={
                    INVOICE_STATUS_TARGET_ACTIONS_ADMIN
                  }
                />
              </Box>
            </Grid2>
          </AllowedAccess>
          {selectedRange === "customDate" && (
            <Grid2 size={{ lg: 12, sm: 12, xs: 12 }} alignSelf={"end"}>
              <DateRangePicker
                start={null}
                end={null}
                onDateChange={(startDate, endDate) => {
                  handleCustomDateChange(startDate, endDate);
                }}
              />
            </Grid2>
          )}
        </Grid2>
        <Grid2
          pr={1}
          size={{ lg: 6, md: 5, xs: 12 }}
          container
          alignItems="center"
          justifyContent={{ xs: "flex-end", md: "flex-end", lg: "flex-end" }}
        >
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <AllowedAccess
              roles={[Role.Admin, Role.SuperAdmin]}
              permissions={[{ module: Module.Invoice, action: Action.Write }]}
            >
              <Button
                label={"Invoice Now"}
                variantType={"secondary"}
                sx={{ height: "30px", p: 1, width: "100%" }}
                icon={ReceiptLongOutlinedIcon}
                onClick={onCreateInvoice}
              />
            </AllowedAccess>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <AllowedAccess
              roles={[Role.Admin, Role.SuperAdmin, Role.Customer]}
              permissions={[{ module: Module.Invoice, action: Action.Read }]}
            >
              <Button
                label={"Download"}
                variantType={"secondary"}
                sx={{ height: "30px", p: 1, width: "100%" }}
                icon={FileDownloadOutlinedIcon}
                onClick={onDownload}
              />
            </AllowedAccess>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <Button
              label={"Refresh"}
              variantType={"secondary"}
              icon={AutorenewOutlinedIcon}
              onClick={onRefresh}
              sx={{ height: "30px", p: 1, width: "100%" }}
            />
          </Grid2>
          <Grid2
            size={{ xs: 12, sm: 4, lg: 1 }}
            sx={{ flexBasis: { xs: "content", sm: "content", lg: "content" } }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "60px",
                height: "30px",
                width: "50px",
                cursor: "pointer",
              }}
              onClick={handleFilterClick}
            >
              <Badge badgeContent={appliedFiltersCount} color="secondary">
                <MdOutlineFilterAlt
                  style={{ fontSize: "22px", color: "white" }}
                />
              </Badge>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Filter
          onApplyFilters={handleApplyFilters}
          filters={filters}
          onClose={handleFilterClose}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
      </Popover>
    </Box>
  );
};
