import { atom } from "jotai";

export const washChargesDataGridPaginationAtom = atom({
  page: 0,
  pageSize: 10,
});
export const miscChargesDataGridPaginationAtom = atom({
  page: 0,
  pageSize: 10,
});
export const fleetsDataGridPaginationAtom = atom({
  page: 0,
  pageSize: 10,
});
export const invoiceDataGridPaginationAtom = atom({
  page: 0,
  pageSize: 10,
});
export const washInventoryDataGridPaginationAtom = atom({
  page: 0,
  pageSize: 10,
});
export const userDataGridPaginationAtom = atom({
  page: 0,
  pageSize: 10,
});
export const washTypeDataGridPaginationAtom = atom({
  page: 0,
  pageSize: 10,
});
export const reportsDataGridPaginationAtom = atom({
  page: 0,
  pageSize: 10,
});
export const createInvoiceDataGridPaginationAtom = atom({
  page: 0,
  pageSize: 10,
});
