import {
  ApiLocationFleet,
  LocationFleet,
  TabItem,
  AccountLocation,
  DropdownItem,
} from "../types";
import { AccountModel } from "./AccountModel";

export class LocationModel {
  public static buildLocations(
    locations: AccountLocation[]
  ): AccountLocation[] {
    return locations.map((location) => ({
      locationNumber: location.locationNumber,
      locationName: location.locationName,
      addressLine1: location.addressLine1,
      addressLine2: location.addressLine2,
      city: location.city,
      state: location.state,
      zip: location.zip,
      email: location.email || "N/A",
      website: location.website || "N/A",
      contactPerson: location.contactPerson || "N/A",
      instruction: location.instruction || "N/A",
    }));
  }

  public static buildLocationFleets(
    accountFleets: ApiLocationFleet[]
  ): LocationFleet[] {
    return accountFleets.map((accountLocationFleet) => ({
      location: {
        name: accountLocationFleet.locationName,

        number: accountLocationFleet.locationNumber,
      },
      fleets: AccountModel.buildFleetsInfo(accountLocationFleet.fleets),
    }));
  }

  public static buildLocationsTab(
    locations: { locationNumber: number; locationName: string }[]
  ): TabItem[] {
    return locations.map((location) => ({
      key: location.locationName,
      value: location.locationNumber,
    }));
  }

  public static buildLocationDropdown(
    locations: { locationNumber: number; name: string }[]
  ): DropdownItem[] {
    return locations.map((location) => ({
      key: location.name,
      value: location.locationNumber,
    }));
  }
}
