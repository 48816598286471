import moment, { Moment } from "moment";
import { DateRange } from "../types";

export const formatDate = (
  date: string | moment.Moment | number,
  format: string = "YYYY-MM-DD"
): string => {
  return moment(date).format(format);
};

export const getDateRange = (range: string): DateRange => {
  const today = moment().startOf("day");
  const endOfToday = moment().endOf("day");

  switch (range) {
    case "today":
      return {
        startDate: formatDate(today),
        endDate: formatDate(endOfToday),
      };
    case "yesterday":
      const yesterday = formatDate(moment().subtract(1, "days").startOf("day"));
      return {
        startDate: formatDate(yesterday),
        endDate: formatDate(yesterday),
      };
    case "thisWeek":
      return {
        startDate: formatDate(moment().startOf("isoWeek")),
        endDate: formatDate(endOfToday),
      };
    case "lastWeek":
      return {
        startDate: formatDate(moment().subtract(1, "weeks").startOf("isoWeek")),
        endDate: formatDate(moment().subtract(1, "weeks").endOf("isoWeek")),
      };
    case "thisMonth":
      return {
        startDate: formatDate(moment().startOf("month")),
        endDate: formatDate(endOfToday),
      };
    case "lastMonth":
      return {
        startDate: formatDate(moment().subtract(1, "months").startOf("month")),
        endDate: formatDate(moment().subtract(1, "months").endOf("month")),
      };
    case "thisQuarter":
      return {
        startDate: formatDate(moment().startOf("quarter")),
        endDate: formatDate(endOfToday),
      };
    case "lastQuarter":
      return {
        startDate: formatDate(
          moment().subtract(1, "quarters").startOf("quarter")
        ),
        endDate: formatDate(moment().subtract(1, "quarters").endOf("quarter")),
      };
    case "last6Months":
      return {
        startDate: formatDate(moment().subtract(6, "months").startOf("day")),
        endDate: formatDate(endOfToday),
      };
    case "last12Months":
      return {
        startDate: formatDate(moment().subtract(12, "months").startOf("day")),
        endDate: formatDate(endOfToday),
      };
    case "yearToDate":
      return {
        startDate: formatDate(moment().startOf("year")),
        endDate: formatDate(endOfToday),
      };
    case "thisYear":
      return {
        startDate: formatDate(moment().startOf("year")),
        endDate: formatDate(endOfToday),
      };
    default:
      return {
        startDate: "",
        endDate: "",
      };
  }
};

export const getDate = (date: string): string => {
  const today = moment().startOf("day");

  switch (date) {
    case "today":
      return formatDate(today);
    case "yesterday":
      const yesterday = formatDate(moment().subtract(1, "days").startOf("day"));
      return formatDate(yesterday);
    default:
      return "";
  }
};

export const utcStringToMoment = (dateString: string): Moment => {
  return moment(dateString);
};

export const addDays = (date: string | moment.Moment, days: number) => {
  return moment(date).add(days, "d");
};

export const hasDatePassed = (date: string | moment.Moment) => {
  const inputDate = moment(date);
  const today = moment().startOf("day");

  return inputDate.isBefore(today);
};

export const isValidDate = (date: string): boolean => {
  const parsedDate = new Date(date);
  const currentDate = new Date();
  return !isNaN(parsedDate.getTime()) && parsedDate <= currentDate;
};
