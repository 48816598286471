import { atom } from "jotai";
import { ReportFilters } from "../types";
import { getDateRange } from "../utils";

const currentDate = getDateRange("thisMonth");

export const ReportFiltersAtom = atom<ReportFilters>({
  fromDate: currentDate.startDate,
  toDate: currentDate.endDate,
});

export const reportSelectedDateRangeAtom = atom<string>("thisMonth");
