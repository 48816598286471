import {
  Box,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import {
  InvoicePaymentCallbackMessage,
  InvoicePaymentStatus,
} from "../../../enums";
import { InvoicePaymentStatusChip } from "../../common/chips";
import { PdfViewer } from "../../common/pdf-viewer";
import { InvoiceOverviewInfo } from "../../../types";
import { addDays, formatDate, hasDatePassed } from "../../../utils";
import { useEffect } from "react";
import {
  isReceivePaymentFormActiveAtom,
} from "../../../atoms";
import { useAtom } from "jotai";
import { PaymentDialog } from "../../payment/PaymentDialog";

type InvoiceOverviewProps = {
  invoiceOverview: InvoiceOverviewInfo;
  refetch: () => void;
};

export const InvoiceOverview = ({ invoiceOverview, refetch }: InvoiceOverviewProps) => {
  const dueDate = formatDate(addDays(invoiceOverview.invoiceDate, 15));
  const [isReceivePaymentFormActive, setIsReceivePaymentFormActive] = useAtom(
    isReceivePaymentFormActiveAtom
  );

  useEffect(() => {
    handleClosePaymentDialog(InvoicePaymentCallbackMessage.None);
  }, []);

  const handleClosePaymentDialog = (message: InvoicePaymentCallbackMessage) => {
    setIsReceivePaymentFormActive(false);
    if (message === InvoicePaymentCallbackMessage.PaymentSuccess) {
      refetch();
    }
  };

  return (
    <Grid
      container
      size={12}
      direction={{ xs: "column", md: "row" }}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      spacing={3}
    >
      <Grid
        container
        size={{ xs: 12, md: 6 }}
        sx={{ px: 3 }}
        gap={3}
        direction="column"
      >
        <Grid
          container
          size={12}
          sx={{ height: "fit-content", display: "flex", gap: 1 }}
        >
          <InvoicePaymentStatusChip
            // TODO: check if paid
            status={
              hasDatePassed(dueDate)
                ? InvoicePaymentStatus.Overdue
                : InvoicePaymentStatus.Pending
            }
          ></InvoicePaymentStatusChip>
        </Grid>
        <Grid container size={12} spacing={3}>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Invoice Amount
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                $ {invoiceOverview.invoiceAmount}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Amount Paid
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                $ {invoiceOverview.paidAmount}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Outstanding Balance
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                $ {invoiceOverview.balanceAmount}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Wash Charges
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                $ {invoiceOverview.totalWashCharge}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Misc Charges
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                $ {invoiceOverview.totalMiscCharge}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Due Date
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                {dueDate}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Paid On
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                -
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        size={{ xs: 12, md: 6 }}
        sx={{
          boxShadow: "0px 0px 14px 0px #00000040",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PdfViewer url={"/sample-files/invoice-sample.pdf"} />
      </Grid>
      <PaymentDialog
        invoiceOverview={invoiceOverview}
        open={isReceivePaymentFormActive}
        handleClose={handleClosePaymentDialog}
      />
    </Grid>
  );
};
