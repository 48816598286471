import { Box, Grid2, Chip, ChipPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { ActionIconsV2, DataGrid, Modal } from "../common";
import { CustomerInfo, User as UserInfo } from "../../types";
import { searchObjects, theme } from "../../utils";
import { useAtom } from "jotai";
import {
  selectedOrgIdAtom,
  toastOptionsAtom,
  userDataGridPaginationAtom,
  userFilterStateAtom,
  userPermissionAtom,
} from "../../atoms";
import { GridColDef } from "@mui/x-data-grid";
import { ChangeEvent, useEffect, useState } from "react";
import { UserForm, UserToolbar } from "./fragments";
import {
  useHasPermission,
  UserPermission,
} from "@zdistancelab-packages/ui-permission-validator";
import { Action, Module, Role } from "../../enums";
import {
  useAdminResetUserPasswordMutation,
  useCreateUserProfileMutation,
  useDeleteUserProfileMutation,
  useDeleteUserProfilesMutation,
  useFetchAllUsersQuery,
  useUpdateUserProfileMutation,
} from "../../api/userService";
import { UserModel } from "../../models";

export const User = () => {
  const [users, setUsers] = useState<UserInfo[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserInfo[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [userPermission] = useAtom<UserPermission>(userPermissionAtom);
  const [selectedFilters] = useAtom(userFilterStateAtom);
  const [userPaginationModel, setUserPaginationModel] = useAtom(
    userDataGridPaginationAtom
  );
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const hasWritePermission = useHasPermission(userPermission, [
    { module: Module.User, action: Action.Write },
  ]);
  const hasDeletePermission = useHasPermission(userPermission, [
    { module: Module.User, action: Action.Delete },
  ]);
  const fetchAllUsersQuery = useFetchAllUsersQuery(selectedOrgId);
  const createUserProfileMutation = useCreateUserProfileMutation();
  const deleteUserProfileMutation = useDeleteUserProfileMutation();
  const deleteUserProfilesMutation = useDeleteUserProfilesMutation();
  const updateUserProfileMutation = useUpdateUserProfileMutation();
  const resetUserPasswordMutation = useAdminResetUserPasswordMutation();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleEditUser = (
    data: Pick<
      CustomerInfo,
      "firstName" | "lastName" | "phoneNumber" | "id" | "emailId"
    > &
      Partial<Pick<CustomerInfo, "emailId">> & {
        roleCode: string;
        orgId: number;
      }
  ) => {
    updateUserProfileMutation.mutate({
      id: data.id,
      orgId: data.orgId,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      roleCode: data.roleCode,
      emailId: data.emailId,
      employeeId: "",
    });

    handleCloseCreateUserModal();
  };

  const handleDeleteUser = (data: UserInfo) => {
    deleteUserProfileMutation.mutate({
      orgId: data.orgId,
      userId: data.id,
    });
  };

  const handleDeleteUsers = () => {
    deleteUserProfilesMutation.mutate({
      orgId: selectedOrgId,
      ids: selectedUserIds,
    });
  };

  const handleResetPassword = () => {
    resetUserPasswordMutation.mutate({
      orgId: selectedOrgId,
      id: selectedUserIds[0],
    });
  };

  const handleApplyFilters = () => {
    setFilteredUsers(getFilteredAndSearchedUsers());
  };

  const handleRowSelectionChange = (rows: UserInfo[]) => {
    setSelectedUserIds(rows.map((e) => e.id));
  };

  const handleOpenCreateUserModal = () => {
    setIsCreateUserModalOpen(true);
  };

  const handleCloseCreateUserModal = () => {
    setIsCreateUserModalOpen(false);
  };

  const getFilteredUsers = () => {
    const filters = selectedFilters;

    const filteredUsers = users.filter((user) => {
      return Object.keys(filters).every((key) => {
        const userValue = (user as any)[key];
        const filterValue = filters[key];
        return userValue === filterValue;
      });
    });
    return filteredUsers;
  };

  const getFilteredAndSearchedUsers = () => {
    const filtered = searchObjects(
      getFilteredUsers(),
      ["employeeStatus", "roleName"],
      searchTerm
    );
    return filtered;
  };

  const handleCreateUserProfile = (
    data: Pick<
      CustomerInfo,
      "firstName" | "lastName" | "phoneNumber" | "id" | "orgId" | "emailId"
    > &
      Partial<Pick<CustomerInfo, "emailId">> & { roleCode: string }
  ) => {
    createUserProfileMutation.mutate({
      orgId: data.orgId || 0,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      roleCode: data.roleCode,
      emailId: data.emailId,
      employeeId: "",
    });

    handleCloseCreateUserModal();
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "userName",
      headerName: "Username",
      width: 150,
      editable: false,
    },
    {
      field: "roleCode",
      headerName: "Role",
      headerAlign: "center",
      align: "center",
      width: 200,
      editable: false,
      renderCell: (params) => {
        const role = params.value;
        const getRoleChipDetails = (
          role: Role
        ): {
          color: OverridableStringUnion<
            | "error"
            | "info"
            | "success"
            | "warning"
            | "default"
            | "primary"
            | "secondary",
            ChipPropsColorOverrides
          >;
          label: string;
          bgColor: string;
        } => {
          switch (role) {
            case Role.SuperAdmin:
              return { color: "error", label: "Super Admin", bgColor: "red" };
            case Role.Admin:
              return { color: "info", label: "Admin", bgColor: "blue" };
            case Role.Lead:
              return { color: "success", label: "Lead", bgColor: "green" };
            case Role.Customer:
              return { color: "warning", label: "Customer", bgColor: "orange" };
            default:
              return { color: "default", label: "User", bgColor: "black" };
          }
        };
        const roleDetails = getRoleChipDetails(role);
        return (
          <Chip
            label={roleDetails.label}
            variant="outlined"
            color={roleDetails.color}
            sx={{
              fontWeight: "bold",
              minWidth: "100px",
              height: "22px",
              textTransform: "uppercase",
              color: "white",
              backgroundColor: `${roleDetails.bgColor}`,
              opacity: 0.7,
            }}
          />
        );
      },
    },
    {
      field: "emailId",
      headerName: "Email Address",
      width: 250,
      editable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "employeeStatus",
      headerName: "Status",
      width: 250,
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (params) => {
        const status = params.value;
        let chipColor: OverridableStringUnion<
            | "error"
            | "info"
            | "warning"
            | "success"
            | "default"
            | "primary"
            | "secondary",
            ChipPropsColorOverrides
          >,
          chipbackgroundColor: string;
        if (status === "active") {
          chipColor = "success";
          chipbackgroundColor = "green";
        } else {
          chipColor = "error";
          chipbackgroundColor = "red";
        }
        return (
          <Chip
            label={status}
            color={chipColor}
            variant="outlined"
            sx={{
              fontWeight: "bold",
              minWidth: "100px",
              height: "22px",
              textTransform: "capitalize",
              color: "white",
              backgroundColor: `${chipbackgroundColor}`,
              opacity: 0.7,
            }}
          />
        );
      },
    },
  ];

  if (hasWritePermission || hasDeletePermission) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: 150,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <ActionIconsV2
            onEdit={handleEditUser}
            onDelete={handleDeleteUser}
            rowData={params.row}
            formProps={{
              form: UserForm,
              name: "editUserForm",
            }}
            editProps={{
              title: "Edit User",
            }}
            deleteProps={{
              title: "Delete User",
            }}
          />
        );
      },
    });
  }

  useEffect(() => {
    setFilteredUsers(getFilteredAndSearchedUsers());
  }, [users]);

  useEffect(() => {
    setFilteredUsers(getFilteredAndSearchedUsers());
  }, [searchTerm]);

  useEffect(() => {
    if (fetchAllUsersQuery.isSuccess) {
      const users = UserModel.buildUser(fetchAllUsersQuery.data);
      setUsers(users);
    }

    if (fetchAllUsersQuery.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    fetchAllUsersQuery.isSuccess,
    fetchAllUsersQuery.data,
    fetchAllUsersQuery.isError,
    fetchAllUsersQuery.isLoading,
    fetchAllUsersQuery.isFetching,
    fetchAllUsersQuery.isFetched,
    fetchAllUsersQuery.isRefetching,
  ]);

  useEffect(() => {
    if (createUserProfileMutation.isSuccess) {
      fetchAllUsersQuery.refetch();
      setToastOptions({
        open: true,
        message: "User created successfully",
        severity: "success",
      });
    }

    if (createUserProfileMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    createUserProfileMutation.isSuccess,
    createUserProfileMutation.data,
    createUserProfileMutation.isError,
    createUserProfileMutation.isLoading,
  ]);

  useEffect(() => {
    if (updateUserProfileMutation.isSuccess) {
      fetchAllUsersQuery.refetch();
      setToastOptions({
        open: true,
        message: "User updated successfully",
        severity: "success",
      });
    }

    if (updateUserProfileMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateUserProfileMutation.isSuccess,
    updateUserProfileMutation.data,
    updateUserProfileMutation.isError,
    updateUserProfileMutation.isLoading,
  ]);

  useEffect(() => {
    if (deleteUserProfileMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "User deleted successfully",
        severity: "success",
      });

      fetchAllUsersQuery.refetch();
    }

    if (deleteUserProfileMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteUserProfileMutation.isSuccess,
    deleteUserProfileMutation.data,
    deleteUserProfileMutation.isError,
    deleteUserProfileMutation.isLoading,
  ]);

  useEffect(() => {
    if (deleteUserProfilesMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Users deleted successfully",
        severity: "success",
      });

      fetchAllUsersQuery.refetch();
    }

    if (deleteUserProfilesMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteUserProfilesMutation.isSuccess,
    deleteUserProfilesMutation.data,
    deleteUserProfilesMutation.isError,
    deleteUserProfilesMutation.isLoading,
  ]);

  useEffect(() => {
    if (resetUserPasswordMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Password reset successfully",
        severity: "success",
      });
    }

    if (resetUserPasswordMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    resetUserPasswordMutation.isSuccess,
    resetUserPasswordMutation.data,
    resetUserPasswordMutation.isError,
    resetUserPasswordMutation.isLoading,
  ]);

  return (
    <>
      <Box
        sx={{
          marginTop: "50px",
          width: "100%",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
        }}
      >
        <Box>
          <Grid2
            container
            direction={"column"}
            sx={{
              marginTop: "0px",
              marginBottom: "8px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <UserToolbar
              onApplyFilters={handleApplyFilters}
              handleSearch={handleSearch}
              selectedUserIds={selectedUserIds}
              handleResetPassword={handleResetPassword}
            />
          </Grid2>
          <Grid2 container direction={"column"} spacing={2}>
            <DataGrid
              columns={columns}
              rows={filteredUsers}
              loading={
                fetchAllUsersQuery.isLoading ||
                fetchAllUsersQuery.isRefetching ||
                createUserProfileMutation.isLoading ||
                deleteUserProfileMutation.isLoading ||
                deleteUserProfilesMutation.isLoading ||
                resetUserPasswordMutation.isLoading
              }
              paginationModel={userPaginationModel}
              setPaginationModel={setUserPaginationModel}
              onSelectedRowsChange={handleRowSelectionChange}
              sx={{
                border: "none",
                backgroundColor: theme.palette.secondary.light,
                padding: "20px",
                paddingTop: "0px",
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                height: "70vh",
              }}
              footerProps={{
                addBtnLabel: hasWritePermission ? "Add User" : "",
                hasAddIcon: true,
                hasRemoveIcon: true,
                removeBtnLabel: hasDeletePermission ? "Delete Selected" : "",
                onAddButtonClick: handleOpenCreateUserModal,
                handleConfirmRemove: handleDeleteUsers,
                disableRemoveBtn: selectedUserIds.length > 0 ? false : true,
                confirmationModalTitle: "Delete Users",
                confirmationModalMessage:
                  selectedUserIds.length == 1
                    ? "Are you sure you want to delete this user?"
                    : `Are you sure you want to delete these ${selectedUserIds.length} users?`,
              }}
            />
          </Grid2>
        </Box>
      </Box>
      <Modal
        open={isCreateUserModalOpen}
        onClose={handleCloseCreateUserModal}
        title={"Add User"}
        content={
          <UserForm
            id={"createUserForm"}
            isCreateMode={true}
            handleEdit={handleCreateUserProfile}
          ></UserForm>
        }
        form={"createUserForm"}
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={handleCloseCreateUserModal}
        sx={{
          modal: {
            width: { lg: "30%", xs: "80%", sm: "50%" },
          },
        }}
      />
    </>
  );
};
