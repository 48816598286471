import { Box, Grid2 } from "@mui/material";
import { DataGrid, Modal, Toast } from "../common";
import {
  InvoiceFilters,
  InvoiceInfo,
  InvoiceItem,
  InvoiceRequestParams,
  ToastOptions,
} from "../../types";
import {
  buildQueryString,
  downloadFile,
  getInvoiceDetailsRoute,
  theme,
} from "../../utils";
import { useAtom } from "jotai";
import {
  invoiceDataGridPaginationAtom,
  selectedAccountAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
  userAtom,
} from "../../atoms";
import {
  useFetchDownloadInvoicesCsvQuery,
  useFetchInvoicesQuery,
} from "../../api/invoiceService";
import { GridColDef, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate, getDateRange } from "../../utils/dateUtil";
import { InvoiceActionIcons, InvoiceToolbar } from "./fragments";
import { InvoiceStatus, Role } from "../../enums";
import { CreateInvoice } from "./CreateInvoice";
import { queryClient } from "../../App";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ACCOUNT_DETAILS_TAB_KEY,
  CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN,
} from "../../constants";
import { useAttachPdfToQuickbooksMutation } from "../../api";

export const Invoice = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [user] = useAtom(userAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [selectedAccount] = useAtom(selectedAccountAtom);
  const [invoiceList, setInvoiceList] = useState<InvoiceInfo>();
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] =
    useState(false);
  const [invoicePaginationModel, setInvoicePaginationModel] = useAtom(
    invoiceDataGridPaginationAtom
  );
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceItem[]>([]);
  const [invoiceFilters, setInvoiceFilters] = useState<InvoiceFilters>({
    fromDate: getDateRange("thisMonth").startDate,
    toDate: getDateRange("today").endDate,
    status:
      user?.roleCode === Role.Customer
        ? String(CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN[0].value)
        : InvoiceStatus.All,
    sortBy: "invoiceDate",
    sortOrder: "desc",
  });
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      const sortField = sortModel[0].field;
      const sortOrder: GridSortDirection = sortModel[0].sort;
      if (sortOrder) {
        setInvoiceFilters({
          ...invoiceFilters,
          sortBy: sortField,
          sortOrder: sortOrder,
        });
      }
    } else {
      setInvoiceFilters({
        ...invoiceFilters,
        sortBy: "",
        sortOrder: "",
      });
    }
  };
  const buildInvoiceRequestParams = (): InvoiceRequestParams => {
    return {
      orgId: selectedOrgId || 0,
      accountId: selectedAccountId || 0,
      filters: {
        page: invoiceFilters?.page ? invoiceFilters?.page + 1 : 1,
        pageSize: invoiceFilters?.pageSize,
        fromDate: invoiceFilters?.fromDate,
        toDate: invoiceFilters?.toDate,
        status:
          invoiceFilters?.status !== InvoiceStatus.All
            ? invoiceFilters?.status
            : "",
        sortBy: invoiceFilters?.sortBy,
        sortOrder: invoiceFilters?.sortOrder,
      },
    };
  };
  const downloadInvoicesQuery = useFetchDownloadInvoicesCsvQuery(
    buildInvoiceRequestParams(),
    {
      enabled: false,
    }
  );
  const fetchInvoicesQuery = useFetchInvoicesQuery(buildInvoiceRequestParams());
  const attachPdfToQuickbooksMutation = useAttachPdfToQuickbooksMutation();

  const columns: GridColDef[] = [
    {
      field: "invoiceDate",
      headerName: "Date",
      width: 230,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      width: 200,
      editable: false,
    },
    {
      field: "invoiceAmount",
      headerName: "Amount",
      width: 150,
      editable: false,
      valueGetter: (value) => {
        return `$ ${parseFloat(value).toFixed(2)}`;
      },
    },
    {
      field: "invoiceStatus",
      headerName: "Status",
      width: 150,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 150,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <InvoiceActionIcons
          invoice={params.row}
          handleAttachPdf={handleAttachPdf}
        />
      ),
    },
  ];

  const handleRowSelectionChange = (rows: InvoiceItem[]) => {
    setSelectedInvoices(rows);
  };

  const handleRefresh = () => {
    fetchInvoicesQuery.refetch();
  };

  const handleDownload = () => {
    downloadInvoicesQuery.refetch();
  };

  const onCreateInvoice = () => {
    setIsCreateInvoiceModalOpen(true);
  };

  const handleCloseCreateInvoiceModal = () => {
    setIsCreateInvoiceModalOpen(false);
  };

  const handleAttachPdf = (invoice: InvoiceItem) => {
    attachPdfToQuickbooksMutation.mutate({
      orgId: invoice.orgId,
      accountId: invoice.accountId,
      invoiceNumber: invoice.invoiceNumber,
    });
  };

  const handleClickInvoiceRow = ({ row }: { row: InvoiceItem }) => {
    if (user) {
      const tab = queryParams.get(ACCOUNT_DETAILS_TAB_KEY);
      const route = getInvoiceDetailsRoute(user);
      const path =
        (tab ? `${tab}/` : "") +
        route.path.replace(":invoiceNumber", row.invoiceNumber).concat(
          buildQueryString({
            orgId: row.orgId,
            accountId: row.accountId,
          })
        );

      navigate(path);
    }
  };

  useEffect(() => {
    if (attachPdfToQuickbooksMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "PDF attached",
        severity: "success",
      });
      handleRefresh();
    }

    if (attachPdfToQuickbooksMutation.isError) {
      setToastOptions({
        open: true,
        message: "Failed to attach PDF",
        severity: "error",
      });
    }
  }, [
    attachPdfToQuickbooksMutation.isLoading,
    attachPdfToQuickbooksMutation.isSuccess,
    attachPdfToQuickbooksMutation.isError,
  ]);

  useEffect(() => {
    if (downloadInvoicesQuery.isSuccess) {
      const accountName = selectedAccount ? selectedAccount.accountName : "";
      const fileName =
        [
          user?.roleCode === Role.SuperAdmin ? "" : accountName || "unknown",
          invoiceFilters.fromDate || "",
          invoiceFilters.fromDate && invoiceFilters.toDate ? "to" : "",
          invoiceFilters.toDate || "",
        ]
          .filter(Boolean)
          .join("_") + "_invoice.csv";
      const blob = new Blob([downloadInvoicesQuery.data], {
        type: "text/csv",
      });
      const linkToCsv = URL.createObjectURL(blob);

      if (linkToCsv) downloadFile(linkToCsv, fileName);

      queryClient.resetQueries("downloadInvoices");
    }
  }, [
    downloadInvoicesQuery.isSuccess,
    downloadInvoicesQuery.data,
    downloadInvoicesQuery.error,
    downloadInvoicesQuery.isError,
    downloadInvoicesQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchInvoicesQuery.isSuccess) {
      setInvoiceList(fetchInvoicesQuery.data);
    }
  }, [fetchInvoicesQuery.isSuccess, fetchInvoicesQuery.data]);

  useEffect(() => {
    setInvoiceFilters({
      ...invoiceFilters,
      page: invoicePaginationModel.page,
      pageSize: invoicePaginationModel.pageSize,
    });
  }, [invoicePaginationModel]);

  return (
    <>
      <Box
        sx={{
          marginTop: "50px",
          width: "100%",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
        }}
      >
        <Toast setOptions={setToastOptions} options={toastOptions} />
        <Box>
          <Grid2
            container
            direction={"column"}
            sx={{
              marginTop: "0px",
              marginBottom: "8px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <InvoiceToolbar
              invoices={selectedInvoices}
              invoiceFilters={invoiceFilters}
              setInvoiceFilters={setInvoiceFilters}
              onRefresh={handleRefresh}
              onDownload={handleDownload}
              onCreateInvoice={onCreateInvoice}
            />
          </Grid2>
          <Grid2 container direction={"column"} spacing={2}>
            <DataGrid
              onRowClick={handleClickInvoiceRow}
              columns={columns}
              rows={invoiceList?.items || []}
              loading={
                fetchInvoicesQuery.isLoading ||
                fetchInvoicesQuery.isRefetching ||
                downloadInvoicesQuery.isLoading ||
                downloadInvoicesQuery.isRefetching ||
                attachPdfToQuickbooksMutation.isLoading
              }
              sortModel={[
                {
                  field: invoiceFilters.sortBy || "",
                  sort: (invoiceFilters.sortOrder as GridSortDirection) || null,
                },
              ]}
              onSortModelChange={handleSortModelChange}
              paginationModel={invoicePaginationModel}
              setPaginationModel={setInvoicePaginationModel}
              onSelectedRowsChange={handleRowSelectionChange}
              totalRecords={invoiceList?.totalItems}
              paginationMode="server"
              sortingMode="server"
              rowCount={invoiceList?.totalItems ? invoiceList?.totalItems : 0}
              pageSize={invoiceList?.pageSize}
              sx={{
                border: "none",
                backgroundColor: theme.palette.secondary.light,
                padding: "20px",
                paddingTop: "0px",
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                height: "70vh",
              }}
            />
          </Grid2>
        </Box>
      </Box>
      <Modal
        open={isCreateInvoiceModalOpen}
        onClose={handleCloseCreateInvoiceModal}
        title={"Create Invoice"}
        content={<CreateInvoice></CreateInvoice>}
        sx={{
          modal: {
            width: { lg: "80%", xs: "80%", sm: "50%" },
            height: { lg: "100%", xs: "80%", sm: "50%" },
          },
        }}
      />
    </>
  );
};
