import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Route } from "../../types";
import { theme } from "../../utils";
import { Box } from "@mui/material";

export const SubMenu = ({
  anchorEl,
  open,
  handleClick,
  handleClose,
  menu,
  parentPath,
}: any) => {
  return (
    <Menu
      sx={{
        "& .MuiList-root": {
          backgroundColor: theme.palette.secondary.main,
        },
        "& .MuiPaper-root": {
          borderRadius: 0,
          boxShadow: "none",
        },
      }}
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {menu.map((item: Route, index: any) => (
        <MenuItem
          key={index}
          onClick={() => {
            handleClick(parentPath, item);
          }}
          sx={{
            py: "8px",
            px: "20px",
            color: "#fff",
            fontWeight: "400",
            fontSize: "1rem",
            ":hover": {
              "& .hover-box": {
                backgroundColor: theme.palette.accent.main,
              },
            },
            display: "flex",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          <Box
            className={"hover-box"}
            sx={{
              backgroundColor: "transparent",
              height: "100%",
              width: "4px",
              position: "absolute",
              left: 0,
            }}
          ></Box>
          {item.icon &&
            React.cloneElement(item.icon, {
              style: {
                fontSize: "23px",
                color: "#a1a1a1",
              },
            })}
          {item.displayName}
        </MenuItem>
      ))}
    </Menu>
  );
};
