import { useEffect, useRef, useState } from "react";
import { InvoiceStatus, Role } from "../../../enums";
import {
  DefaultActionMenuItem,
  InvoiceStatusTargetActions,
} from "../../../types";
import {
  isReceivePaymentFormActiveAtom,
  toastOptionsAtom,
  userAtom,
} from "../../../atoms";
import { useAtom } from "jotai";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import SendIcon from "@mui/icons-material/Send";
import PaymentIcon from "@mui/icons-material/Payment";
import { DefaultActionMenu } from "../../common";
import { useBulkUpdateInvoiceStatusMutation } from "../../../api/invoiceService";
import { useSendInvoiceToQuickbooksMutation } from "../../../api";

type InvoiceActionsProps = {
  status: InvoiceStatus;
  invoiceNumbers: string[];
  orgId: number;
  accountId: number;
  invoiceStatusTargetActions: InvoiceStatusTargetActions;
  disabled?: boolean;
  handleRefresh?: () => void;
};

export const InvoiceActions = ({
  status,
  invoiceNumbers,
  orgId,
  accountId,
  invoiceStatusTargetActions,
  disabled,
  handleRefresh,
}: InvoiceActionsProps) => {
  // TODO: implement loading screen
  const [user] = useAtom(userAtom);
  const invoiceNumbersRef = useRef(invoiceNumbers);
  const [invoiceActions, setInvoiceActions] = useState<DefaultActionMenuItem[]>(
    []
  );
  const [, setIsReceivePaymentFormActive] = useAtom(
    isReceivePaymentFormActiveAtom
  );
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const bulkUpdateInvoiceStatusMutation = useBulkUpdateInvoiceStatusMutation();
  const sendInvoiceToQuickbooksMutation = useSendInvoiceToQuickbooksMutation();

  const handleStatusUpdate = (status: InvoiceStatus) => {
    bulkUpdateInvoiceStatusMutation.mutate({
      accountId,
      orgId,
      invoiceNumbers: invoiceNumbersRef.current,
      invoiceStatus: status,
    });
  };

  const handleSendInvoiceToQuickbooks = () => {
    sendInvoiceToQuickbooksMutation.mutate({
      orgId,
      accountId,
      invoiceNumber: invoiceNumbers[0],
    });
  };

  const allInvoiceActions: {
    [key in InvoiceStatus]?: DefaultActionMenuItem;
  } = {
    [InvoiceStatus.Cancelled]: {
      label: "Cancel Invoice",
      icon: CancelIcon,
      action: () => {
        handleStatusUpdate(InvoiceStatus.Cancelled);
      },
    },
    [InvoiceStatus.OnHold]: {
      label: "Hold Invoice",
      icon: HourglassTopIcon,
      action: () => {
        handleStatusUpdate(InvoiceStatus.OnHold);
      },
    },
    [InvoiceStatus.Pending]: {
      label: "To Pending",
      icon: PendingIcon,
      action: () => {
        handleStatusUpdate(InvoiceStatus.Pending);
      },
    },
    [InvoiceStatus.InProgress]: {
      label: "To Progress",
      icon: RestartAltIcon,
      action: () => {
        handleStatusUpdate(InvoiceStatus.InProgress);
      },
    },
    [InvoiceStatus.PaymentReceived]: {
      label:
        user?.roleCode === Role.Customer ? "Make Payment" : "Receive Payment",
      icon: PaymentIcon,
      action: () => {
        setIsReceivePaymentFormActive(true);
        // handleStatusUpdate(InvoiceStatus.PaymentReceived);
      },
    },
    [InvoiceStatus.SendToCustomer]: {
      label: "To Customer",
      icon: SendIcon,
      action: () => {
        handleStatusUpdate(InvoiceStatus.SendToCustomer);
      },
    },
    [InvoiceStatus.SendToQuickbooks]: {
      label: "Send to Quickbooks",
      icon: SendIcon,
      action: () => {
        handleSendInvoiceToQuickbooks();
      },
    },
  };

  useEffect(() => {
    setIsReceivePaymentFormActive(false);
  }, []);

  useEffect(() => {
    setInvoiceActions(getInvoiceActions(status, invoiceNumbers));
  }, [status, invoiceNumbers]);

  useEffect(() => {
    invoiceNumbersRef.current = invoiceNumbers;
  }, [invoiceNumbers]);

  useEffect(() => {
    if (bulkUpdateInvoiceStatusMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Status updated successfully",
        severity: "success",
      });
      handleRefresh && handleRefresh();
    }

    if (bulkUpdateInvoiceStatusMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    bulkUpdateInvoiceStatusMutation.isSuccess,
    bulkUpdateInvoiceStatusMutation.isError,
  ]);

  useEffect(() => {
    if (sendInvoiceToQuickbooksMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Invoice sent to quickbooks",
        severity: "success",
      });
      handleRefresh && handleRefresh();
    }

    if (sendInvoiceToQuickbooksMutation.isError) {
      // TODO: show proper message. (Missing token, missing credentials, try connecting to qb)
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    sendInvoiceToQuickbooksMutation.isLoading,
    sendInvoiceToQuickbooksMutation.isSuccess,
    sendInvoiceToQuickbooksMutation.isError,
  ]);

  const getInvoiceActions = (
    status: InvoiceStatus,
    invoiceNumbers: string[]
  ): DefaultActionMenuItem[] => {
    const makeDefault = (
      item?: DefaultActionMenuItem
    ): DefaultActionMenuItem | undefined => {
      return item
        ? {
            default: true,
            ...item,
          }
        : item;
    };

    if (
      !Object.keys(invoiceStatusTargetActions).includes(status) ||
      !invoiceStatusTargetActions[status]
    ) {
      return [];
    }

    const { defaultAction, targetActions } = invoiceStatusTargetActions[status];

    const invoiceActions = [
      makeDefault(allInvoiceActions[defaultAction]),
      invoiceNumbers.length === 1 && status === InvoiceStatus.All
        ? allInvoiceActions[InvoiceStatus.SendToQuickbooks]
        : undefined,
      ...(targetActions || []).map((key) => allInvoiceActions[key]),
    ].filter((e) => e !== undefined);

    return invoiceActions;
  };

  return <DefaultActionMenu disabled={disabled} menu={invoiceActions} />;
};
