import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme } from "./utils";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toast } from "./components/common";
import { useAtom } from "jotai";
import { routesAtom, toastOptionsAtom, userAtom } from "./atoms";
import { DashboardLayout } from "./components/layouts";
import { pdfjs } from "react-pdf";
import {
  allDashboardRoutes,
  ErrorRoute,
  LoginRoute,
  openRoutes,
  Router,
} from "./routes";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const [toastOptions, setToastOptions] = useAtom(toastOptionsAtom);
  const [user] = useAtom(userAtom);
  const [routes] = useAtom(routesAtom);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {user && routes && <DashboardLayout routes={routes} user={user} />}
            {!user && (
              <>
                <Router routes={openRoutes} />
                <Router
                  redirectRoutes={allDashboardRoutes}
                  redirectTo={LoginRoute.path}
                />
                <Router
                  errorRoute={ErrorRoute}
                  errorRouteIgnore={[...allDashboardRoutes, ...openRoutes]}
                />
              </>
            )}
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
      <Toast setOptions={setToastOptions} options={toastOptions} />
    </>
  );
};

export default App;
